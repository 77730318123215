import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';


export default function Loading({ loading, children}) {
  return (
    loading ? 
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1%" }}>  
            <LoadingOutlined style={{ fontSize: '120px', color: '#fdfdfd' }}  /> 
        </div>
        : children
  );
}