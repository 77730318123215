const selectedHeaders = [
  {
    label: "ZN9_FILORI",
    key: "ZN9_FILORI",
  },
  {
    label: "ZN9_FEIRA",
    key: "ZN9_FEIRA",
  },
  {
    label: "ZN9_CTRAPP",
    key: "ZN9_CTRAPP",
  },
  {
    label: "ZN9_EMISSA",
    key: "ZN9_EMISSA",
  },
  {
    label: "ZN9_FRETE",
    key: "ZN9_FRETE",
  },
  {
    label: "ZN9_VEND",
    key: "ZN9_VEND",
  },
  {
    label: "ZN9_CLI",
    key: "ZN9_CLI",
  },
  {
    label: "ZN9_LOJA",
    key: "ZN9_LOJA",
  },
  {
    label: "ZN9_COMFEI",
    key: "ZN9_COMFEI",
  },
  {
    label: "ZN9_USER",
    key: "ZN9_USER",
  },
  {
    label: "ZN9_OBS",
    key: "ZN9_OBS",
  },
  {
    label: "ZN9_RETI",
    key: "ZN9_RETI",
  },
  {
    label: "ZN9_PRODUT",
    key: "ZN9_PRODUT",
  },
  {
    label: "ZN9_UM",
    key: "ZN9_UM",
  },
  {
    label: "ZN9_PRECO",
    key: "ZN9_PRECO",
  },
  {
    label: "ZN9_QT06",
    key: "ZN9_QT06",
  },
  {
    label: "ZN9_QT07",
    key: "ZN9_QT07",
  },
  {
    label: "ZN9_QT08",
    key: "ZN9_QT08",
  },
  {
    label: "ZN9_QT09",
    key: "ZN9_QT09",
  },
  {
    label: "ZN9_QT10",
    key: "ZN9_QT10",
  },
  {
    label: "ZN9_QT11",
    key: "ZN9_QT11",
  },
  {
    label: "ZN9_QT12",
    key: "ZN9_QT12",
  },

  {
    label: "ZN9_QTDTOT",
    key: "ZN9_QTDTOT",
  },
  {
    label: "ZN9_TOTAL",
    key: "ZN9_TOTAL",
  },
];

export default selectedHeaders;
