import {
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_FAIL,
  UPDATE_PRODUCT_SUCCESS,
} from './types';
import Api from '../../api/api';

export const updateProduct = (payload) => (dispatch) => {
  dispatch({
    type: UPDATE_PRODUCT,
  });
  Api.post('product/edit', payload, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }).then((res) => {
    if (res.data) {
      dispatch({
        type: UPDATE_PRODUCT_SUCCESS,
        payload: {
          data: res.data,
        },
      });
      window.location.reload();
    } else {
      dispatch({
        type: UPDATE_PRODUCT_FAIL,
      });
    }
  });
};
