import { FETCH_CATEGORIES, FETCH_CATEGORIES_SUCCESS, FETCH_CATEGORIES_FAIL } from "../../actions/Category/types"


const INITIAL_STATE = {
    loading: false,
    fail: false,
    categories: [],
    typeCategory: null,
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_CATEGORIES:
            return {
                ...state,
                loading: true,
            }
        case FETCH_CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false,
                fail: false,
                categories: action.payload.data.list,
                typeCategory: action.payload.data.type_product,
            }
        case FETCH_CATEGORIES_FAIL:
            return {
                ...state,
                loading: false,
                fail: true,
            }
        default:
            return state
    }
}
