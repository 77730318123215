import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Empty, Table } from "antd";
import "antd/dist/antd.less";
import moment from "moment";
import React from "react";
import { changeOrderStatus, fetchOrders } from "../../actions/Order/actions";
import { cpfMask } from "../../utils/cpfMask";
import fixDecimalNumber from "../../utils/fixDecimalNumber";
import fixDecimalPlace from "../../utils/productPriceFormatter";
import TableModal from "../TableModal";
import * as styles from "./index.module.scss";
import "./style.css";

export default function ReportsTable({
  receivedData,
  setData,
  dispatch,
  pagination,
  loading,
  filterOptions,
  year,
}) {
  const formattedData = () => {
    var formattedObject;
    var ordersArray = [];
    for (var i = 0; i < receivedData.length; i++) {
      var { cnpj_cpf, name, email, phone_number, code } = receivedData[
        i
      ].client;
      var {
        total,
        id,
        amount,
        status,
        withdrawal_location_id,
        is_exported,
        created_date,
      } = receivedData[i];
      var { price, mass_unit, masss_unit_quantity, limit_month } = receivedData[
        i
      ].product;
      var {
        jun,
        jul,
        ago,
        set,
        out,
        nov,
        dez,
        jan,
        fev,
        mar,
        abr,
      } = receivedData[i].monthsShipment ? receivedData[i].monthsShipment : {};
      var months = {
        jun,
        jul,
        ago,
        set,
        out,
        nov,
        dez,
        jan,
        fev,
        mar,
        abr,
      };
      formattedObject = {
        key: id,
        rep_id:
          receivedData[i].rep_id === null
            ? "-"
            : receivedData[i].rep?.company_id,
        product_name: receivedData[i].product.name,
        cnpj_cpf: cpfMask(cnpj_cpf),
        phone_number,
        email,
        name,
        code,
        price: `R$ ${fixDecimalNumber(fixDecimalPlace(price))}/${mass_unit}`,
        total: `R$ ${fixDecimalNumber(fixDecimalPlace(total, ",", ".", 6))}`,
        order_id: id,
        months,
        amount: `${amount / masss_unit_quantity} ${mass_unit} (${
          amount / 1000
        } kg)`,
        limit_month,
        masss_unit_quantity,
        contract_status: status,
        created_date,
        is_exported,
        withdrawal_location_id,
      };
      ordersArray.push(formattedObject);
    }

    return ordersArray;
  };

  const renderContractStatusButton = (contractData) => {
    const { exportOption, status, code, branch } = filterOptions;

    const handleAccept = () => {
      dispatch(async () => {
        await changeOrderStatus(contractData.order_id, "approved")(dispatch);
        await fetchOrders(
          pagination.page,
          exportOption,
          status,
          code,
          branch,
          year
        )(dispatch);
      });
    };

    const handleDenied = () => {
      dispatch(async () => {
        await changeOrderStatus(contractData.order_id, "denied")(dispatch);
        await fetchOrders(
          pagination.page,
          exportOption,
          status,
          code,
          branch
        )(dispatch);
      });
    };

    switch (contractData.contract_status) {
      case "pending":
        return (
          <div className={styles.confirmButtonsContent}>
            <Button
              type="primary"
              style={{ marginBottom: "2px" }}
              className={styles.button}
              onClick={handleAccept}
            >
              <CheckOutlined />
              <span>Aprovar</span>
            </Button>
            <Button type="danger" onClick={handleDenied}>
              <CloseOutlined />
              <span>Negar</span>
            </Button>
          </div>
        );
      case "approved":
        return (
          <div
            className={styles.statusButtonContent}
            style={{ backgroundColor: "green" }}
          >
            <span className={styles.text}>ACEITO</span>
          </div>
        );
      case "denied":
        return (
          <div
            className={styles.statusButtonContent}
            style={{ backgroundColor: "red" }}
          >
            <span className={styles.text}>NEGADO</span>
          </div>
        );
      default:
        return <div></div>;
    }
  };

  const rowSelection = {
    onChange: (selectedRowK, selectedRow) => {
      setData[1](selectedRowK);
    },
    getCheckboxProps: (records) => ({
      disabled: records.is_exported === true,
    }),
  };
  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      render: (name) => (
        <span style={{ color: "white", fontWeight: "bold" }}>{name}</span>
      ),
    },
    {
      title: "Matrícula",
      dataIndex: "code",
    },
    {
      title: "Número do pedido",
      dataIndex: "order_id",
    },
    {
      title: "Data de Criação ",
      dataIndex: "created_date",
      render: (data) => (
        <span style={{ whiteSpace: "nowrap" }}>
          {moment(data).format("DD/MM/YYYY - hh:mm")}
        </span>
      ),
    },
    {
      title: "Produto",
      dataIndex: "product_name",
    },
    {
      title: "Representante",
      dataIndex: "rep_id",
      render: (rep) => (rep === "-" ? "Sem representante" : rep),
    },

    {
      title: "Preço Total",
      dataIndex: "total",
    },
    {
      title: "Preço/Unidade de Massa",
      dataIndex: "price",
    },
    {
      title: "Quantidade total",
      dataIndex: "amount",
    },
    {},
    {
      title: "Detalhes",
      render: (rowData) => <TableModal rowData={rowData} />,
    },
    {
      title: "Status",
      render: (contractData) => renderContractStatusButton(contractData),
    },

    /* ,
    {
      title: "Status",
      key: "tags",
      dataIndex: "status",
      render: (tag) => (
        <Tag color={tag === "exported" ? "red" : "black "} key={tag}>
          {tag.toUpperCase()}
        </Tag>
      ),
    }, */
  ];
  const handlePagination = (value) => {
    const { exportOption, status, code, branch } = filterOptions;
    dispatch(fetchOrders(value, exportOption, status, code, branch, year));
  };
  if (receivedData === null) {
    return null;
  } else {
    return (
      <Table
        loading={!!loading}
        columns={columns}
        pagination={{
          onChange: handlePagination,
          current: pagination.page,
          pageSize: 10,
          total: pagination ? pagination.total : null,
          defaultCurrent: 1,
          showTotal: (total, range) => (
            <span style={{ color: "white" }}>
              {range[0] < 0 ? "" : range[0]} - {range[1]} de total contratos
            </span>
          ),
        }}
        locale={{
          emptyText: <Empty description="Sem contratos no momento" />,
        }}
        dataSource={formattedData()}
        rowSelection={rowSelection}
        style={{
          width: "100%",
          minWidth: "100%",
          overflow: "auto",
          borderRadius: 10,
          fontFamily: "Kostic_Roc_Grotesk_Regular",
        }}
        className={"RCM_two_level_table1"}
        scrollToFirstRowOnChange={true}
      />
    );
  }
}
