import * as c from "./types";
import Api from "../../api/api";

export const fetchUndervWallet = () => async (dispatch) => {
  try {
    dispatch({
      type: c.FETCH_UNDERV_WALLET,
    });
    const res = await Api.get("walletunderevaluation/all", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    if (res.data) {
      dispatch({
        type: c.FETCH_UNDERV_WALLET_SUCCESS,
        payload: {
          data: res.data,
        },
      });
    } else {
      dispatch({
        type: c.FETCH_UNDERV_WALLET_FAIL,
      });
    }
  } catch (error) {
    dispatch({
      type: c.FETCH_UNDERV_WALLET_FAIL,
    });
  }
};

export const acceptUndervWallet = (key) => async (dispatch) => {
  try {
    dispatch({
      type: c.ACCEPT_UNDERV_WALLET,
    });

    let payload = {
      wallet_id: key,
    };
    var res = await Api.post("walletunderevaluation/accept", payload, {
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    if (res.status === 200) {
      dispatch({
        type: c.ACCEPT_UNDERV_WALLET_SUCCESS,
      });
    } else {
      var message;
      if (
        res.response.data.error === "it was not possible create a new wallet"
      ) {
        message = "Telefone do representante já cadastrado na plataforma";
      }
      await dispatch({
        type: c.ACCEPT_UNDERV_WALLET_FAIL,
        payload: {
          message: message,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: c.ACCEPT_UNDERV_WALLET_FAIL,
      payload: {
        message: message,
      },
    });
  }
};

export const rejectUndervWallet = (key, setArr) => async (dispatch) => {
  try {
    dispatch({
      type: c.REJECT_UNDERV_WALLET,
    });
    let payload = {
      wallet_id: key,
    };
    const res = await Api.post("walletunderevaluation/delete", payload, {
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    if (res.status === 200) {
      dispatch({
        type: c.REJECT_UNDERV_WALLET_SUCCESS,
      });
    } else {
      dispatch({
        type: c.REJECT_UNDERV_WALLET_FAIL,
      });
    }
  } catch (error) {
    dispatch({
      type: c.REJECT_UNDERV_WALLET_FAIL,
    });
  }
};
