import { notification } from "antd";
import Api from "../../api/api";
import * as c from "./types";
export const fetchProducts = ({ product }) => (dispatch, getState) => {
  try {
    dispatch({
      type: c.FETCH_PRODUCTS,
    });
    if (!product) throw new Error("undefined subcategory");
    Api.get(`admin/product/type?type_product_id=${product}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.data) {
          dispatch({
            type: c.FETCH_PRODUCTS_SUCCESS,
            payload: {
              data: res.data,
            },
          });
        } else {
          dispatch({
            type: c.FETCH_PRODUCTS_FAIL,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: c.FETCH_PRODUCTS_FAIL,
        });
      });
  } catch (error) {
    console.log(error);
    dispatch({
      type: c.FETCH_PRODUCTS_FAIL,
    });
  }
};

export const createProduct = (payload) => (dispatch) => {
  dispatch({
    type: c.CREATE_PRODUCT,
  });
  Api.post("product/register", payload, {
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: c.CREATE_PRODUCT_SUCCESS,
        });
        window.location.reload();
        notification.success({
          message: "Produto Criado com Sucesso",
        });
      } else {
        dispatch({
          type: c.CREATE_PRODUCT_FAIL,
        });
        notification.error({
          message: "Ocorreu um erro durante a criação do produto!",
        });
      }
    })
    .catch((err) => {
      if (err) {
        dispatch({
          type: c.CREATE_PRODUCT_FAIL,
        });
      }
    });
};

export const updateProductStatus = (payload) => (dispatch) => {
  dispatch({
    type: c.UPDATE_PRODUCT_STATUS,
  });

  Api.post("products/status/all", payload, {
    headers: {
      authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: c.UPDATE_PRODUCT_STATUS_SUCCESS,
        });
        notification.success({
          message: "Produtos atualizados com Sucesso",
          duration: 5000,
        });
      } else {
        dispatch({
          type: c.UPDATE_PRODUCT_STATUS_FAIL,
        });
        notification.error({
          message: "Não foi possível atualizar os produtos",
          duration: 5000,
        });
      }
    })
    .catch((err) => {
      if (err) {
        dispatch({
          type: c.CREATE_PRODUCT_FAIL,
        });
      }
    });
};
