import {
  FETCH_USER,
  FETCH_USER_FAIL,
  FETCH_USER_SUCCESS,
} from '../../actions/userFetchAction/types';

const initialState = {
  user: null,
  loading: false,
  fail: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_USER:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.data,
        loading: false,
      };
    case FETCH_USER_FAIL:
      return {
        ...state,
        fail: true,
        loading: false,
      };
    default: {
      return {
        ...state,
      };
    }
  }
}
