import React, { useState } from "react";
import { Table, Popconfirm, Form, Button, Empty, notification } from "antd";
import EditableCell from "./EditableCell";
import "./style.css";
import { cpfMask } from "../../utils/cpfMask";
import phoneMask from "../../utils/phoneMask";
import {
  deleteClients,
  updateClient,
  fetchClientsByStatus,
} from "../../actions/Clients/clientsActions";
import "../ReportsTable/style.css";

const EditableTable = (props) => {
  const [form] = Form.useForm();
  // const [data, setData] = useState(props.data);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;
  var formattedData = [];
  
  for (var i = 0; i < props.data.length; i++) {
    var formattedObject = {
      key: props.data[i].id,
      name: props.data[i].name,
      cnpj_cpf: props.data[i].cnpj_cpf,
      person_type: props.data[i].person_type,
      code: props.data[i].code,
      email: props.data[i].email,
      phone_number: props.data[i].phone_number,
      validation_status: props.data[i].validation_status,
    };
    formattedData.push(formattedObject);
  }
  const edit = (record) => {
    const dataEdit = {
      id: record.key,
      name: "",
      cnpj_cpf: "",
      email: "",
      phone_number: phoneMask(record.phone_number),
      ...record,
    }
    form.setFieldsValue(dataEdit)
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = (key) => {
    try {
      const dataUpdate = {
        ...form.getFieldsValue(),
        id: editingKey
      }

      if(!editingKey) return;
      props.dispatch(async () => {
        await updateClient(dataUpdate)(props.dispatch)
        await fetchClientsByStatus(props.pagination.page, "old", props.filterName)(props.dispatch)
        setEditingKey("");
      })
      
    } catch (errInfo) {}
  };

  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      editable: true,
    },
    {
      title: "CPF/CNPJ",
      dataIndex: "cnpj_cpf",
      render: (cnpj_cpf) => <span>{cpfMask(cnpj_cpf)}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      editable: true,
    },
    {
      title: "Matrícula",
      dataIndex: "code",
      editable: true,
    },
    {
      title: "Telefone",
      dataIndex: "phone_number",
      editable: true,
      render: (record) => <span>{phoneMask(record)}</span>,
    },
    {
      title: "Editar",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Popconfirm
              cancelText="Cancelar"
              okText="Salvar"
              title="Certeza que quer Salvar ?"
              onConfirm={() => {
                save(record.key);
                cancel();
              }}
            >
              <span
                style={{
                  marginRight: 8,
                  cursor: "pointer",
                }}
              >
                Salvar
              </span>
            </Popconfirm>
            <Popconfirm
              onConfirm={() => cancel()}
              okText="Sim"
              cancelText="Não"
              title="Certeza que quer cancelar ?"
            >
              <span style={{ cursor: "pointer" }}>Cancelar</span>
            </Popconfirm>
          </span>
        ) : (
          <span
            disabled={editingKey !== ""}
            onClick={() => {
              edit(record);
            }}
            style={{ color: "#37724a", fontWeight: "bold", cursor: "pointer" }}
          >
            Editar
          </span>
        );
      },
    },
    {
      title: "Deletar",
      render: (rowData) => {
        const handleDelete = () => {
          props.dispatch( async () => {
            await deleteClients(rowData.key)(props.dispatch)
            await fetchClientsByStatus(props.pagination.page, "old", props.filterName)(props.dispatch)
          })
        }

        return (
          <span
            style={{
              fontFamily: "Kostic_Roc_Grotesk_Bold",
            }}
          >
            <Popconfirm
              title="Certeza quer quer deletar esse usuário?"
              okText="Deletar"
              cancelText="Cancelar"
              onConfirm={handleDelete}
            >
              <Button
                type="primary"
                style={{
                  backgroundColor: "#f22",
                  border: "none",
                  marginLeft: 5,
                }}
              >
                Deletar
              </Button>
            </Popconfirm>
          </span>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handlePagination = (value) => {
    props.dispatch(fetchClientsByStatus(value, "old", props.name));
  };
  return (
    <Form form={form} component={false}>
      <Table
        loading={props.loading}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        style={{
          width: "100%",
          minWidth: "100%",
          overflow: "hidden",
          borderRadius: 10,
          fontFamily: "Kostic_Roc_Grotesk_Regular  ",
        }}
        pagination={{
          onChange: handlePagination,
          current: props.pagination.page ? props.pagination.page : 1,
          pageSize: 10,
          total: props.pagination ? props.pagination.total : null,
          showTotal: (total, range) => (
            <span style={{ color: "white" }}>
              {range[0]} - {range[1]} de total contratos
            </span>
          ),
        }}
        dataSource={formattedData}
        columns={mergedColumns}
        locale={{
          emptyText: (
            <Empty description="Sem clientes disponíveis no momento" />
          ),
        }}
        rowClassName="editable-row"
        className={"RCM_two_level_table1"}
      />
    </Form>
  );
};

export default EditableTable;
