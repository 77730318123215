import * as c from './types';
import Api from '../../api/api';

export const fetchCategory = ({ category }) => (dispatch, getState) => {
    try {
        dispatch({
            type: c.FETCH_CATEGORY,
        })
        if(!category) throw new Error('Category undefined')
        Api.get(`admin/typeproduct/id?type_product_id=${category}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }).then(res => {
            if (res.data) {
                dispatch({
                    type: c.FETCH_CATEGORY_SUCCESS,
                    payload: {
                        data: res.data
                    }
                })
            } else {
                dispatch({
                    type: c.FETCH_CATEGORY_FAIL,
                })
            }
        }).catch(err => {
            dispatch({
                type: c.FETCH_CATEGORY_FAIL,
            })
        })
    } catch (error) {
        dispatch({
            type: c.FETCH_CATEGORY_FAIL,
        })
    }
}