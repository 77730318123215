import * as c from '../../actions/CategoryId/types'


const INITIAL_STATE = {
    loading: false,
    fail: false,
    category: null,
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case c.FETCH_CATEGORY:
            return {
                ...state,
                loading: true,
            }
        case c.FETCH_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                fail: false,
                category: action.payload.data,
            }
        case c.FETCH_CATEGORY_FAIL:
            return {
                ...state,
                loading: false,
                fail: true,
            }
        default:
            return state
    }
}
