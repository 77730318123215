import * as c from "./types";
import Api from "../../api/api";
import { notification } from "antd";
import { cpfMask } from "../../utils/cpfMask";

export const fetchClientsByStatus = (page, status, name) => async (
  dispatch
) => {
  dispatch({
    type: c.FETCH_CLIENTS,
  });
  const res = await Api.get(
    `preregistration/list?page=${page}&perpage=10&status=${status}&name=${name}`,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
  )

  if (res.data) {
    dispatch({
      type: c.FETCH_CLIENTS_SUCCESS,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination,
      },
    });
  } else {
    dispatch({
      type: c.FETCH_CLIENTS_FAIL,
    });
  }
  
};

export const deleteClients = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: c.DELETE_CLIENTS,
    });
    const res = await Api.post(
        "preregistration/delete",
        {
          id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
    if (res.status === 204) {
      dispatch({
        type: c.DELETE_CLIENTS_SUCCESS,
      });
      
    } else {
      dispatch({
        type: c.DELETE_CLIENTS_FAIL,
      });
    }
  } catch (error) {
    console.log(error)
    dispatch({
      type: c.DELETE_CLIENTS_FAIL,
    })
  }
};

export const acceptClient = (cpf) => async (dispatch) => {
  try{
    dispatch({
      type: c.ACCEPT_CLIENT,
    });
    let cnpj_cpf = cpf.replace(".", "").replace(".", "").replace("-", "");
    const res = await Api.post(`preregistration/validate?cnpj_cpf=${cnpj_cpf}`, null, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    
    if (res.status === 200) {
      dispatch({
        type: c.ACCEPT_CLIENT_SUCCESS,
      });
    } else {
      dispatch({
        type: c.ACCEPT_CLIENT_FAIL,
      });
    }
  }catch(err){
    dispatch({
      type: c.ACCEPT_CLIENT_FAIL,
    });
  }
};

export const updateClient = ({
  name,
  cnpj_cpf,
  code,
  email,
  id,
  phone_number,
}) => async (dispatch) => {
  try {
    let payload = {
      id,
    }
    if(name)
      payload.name = name
    if(cnpj_cpf)
      payload.cpf_cnpj = cnpj_cpf.replace(".", "").replace(".", "").replace("-", "")
    if(code)
      payload.code = code
    if(email)
      payload.mail = email
    if(phone_number)
      payload.phone = phone_number
    
    dispatch({
      type: c.UPDATE_CLIENT,
    });
    const res = await Api.post("preregistration/edit", payload, {
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    
    if (res.status === 200 && res.data.Status) {
      dispatch({
        type: c.UPDATE_CLIENT_SUCCESS,
      });
    } else {
      dispatch({
        type: c.UPDATE_CLIENT_FAIL,
      });
    }
  } catch (error) {
    console.log(error)
    dispatch({
      type: c.UPDATE_CLIENT_FAIL,
    }) 
  }
};

export const fetchallClients = (page) => (dispatch, getState) => {
  dispatch({
    type: c.FETCH_ALL_CLIENTS,
  });
  Api.get(`admin/users/list?page=${page}&perpage=10`, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then((res) => {
      if (res.status === 200 || res.data.Users.data) {
        dispatch({
          type: c.FETCH_ALL_CLIENTS_SUCCESS,
          payload: {
            data: res.data.Users.data,
            pagination: {
              total: res.data.Users.total,
              page: res.data.Users.page,
              perPage: res.data.Users.perPage,
            },
          },
        });
      } else {
        dispatch({
          type: c.FETCH_ALL_CLIENTS_FAIL,
        });
      }
    })
    .catch((err) => {
      if (err) {
        dispatch({
          type: c.FETCH_ALL_CLIENTS_FAIL,
        });
      }
    });
};

export const createClient = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: c.CREATE_CLIENT,
    });

    const res = await Api.post("preregistration/create", payload, {
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    if (res.status === 200) {
      dispatch({
        type: c.CREATE_CLIENT_SUCCESS,
      });
      // "ER_DUP_ENTRY"
    } else {

      let message = ''
      if(res.response.data['User Already Exists'] === 'ER_DUP_ENTRY')
        message = "Usuário Já cadastrado"
      else if(res.response.data.messages.errors[0]){
        const { pointer } = res.response.data.messages.errors[0].source
        switch (pointer) {
          case "cpf_cnpj":
            message = "Cpf/cnpj inválido"
            break;
          case "name":
            message = "Nome inválido"
            break;
          case "user_type":
            message = "Tipo de usuário inválido"
            break;
          case "code":
            message = "Matrícula inválida"
            break;
          case "mail":
            message = "E-mail inválido"
            break;
          case "phone":
            message = "Celular inválido"
            break;          
          case "company_code":
            message = "Empresa inválida"
            break;
          default:
            message = "Campo inválido"
            break;
        }
      }
      else message = "Não foi possível criar um novo usuário!"
      dispatch({
        type: c.CREATE_CLIENT_FAIL,
        payload: {
          message,
        }
      });
    }
  } catch (error) {
    console.log(error)
    dispatch({
      type: c.CREATE_CLIENT_FAIL,
      payload: {
        message: "Não foi possível criar um novo usuário!",
      }
    });  
  }
};
