import * as c from "../../actions/Product/types";

const INITIAL_STATE = {
  loading: false,
  fail: false,
  products: [],
  typeProduct: null,
  updatedProduct: {
    loading: false,
    fail: false,
  },

  updateProductStatus: {
    loading: false,
    fail: false,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case c.FETCH_PRODUCTS:
      return {
        ...state,
        loading: true,
      };
    case c.FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        fail: false,
        products: action.payload.data.list,
        typeProduct: action.payload.data.type_product,
      };
    case c.FETCH_PRODUCTS_FAIL:
      return {
        ...state,
        loading: false,
        fail: true,
      };
    case c.CREATE_PRODUCT:
      return {
        ...state,
        updatedProduct: {
          loading: true,
        },
      };
    case c.CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        updatedProduct: {
          loading: false,
        },
      };
    case c.CREATE_PRODUCT_FAIL:
      return {
        ...state,
        updatedProduct: {
          loading: false,
          fail: true,
        },
      };
    case c.UPDATE_PRODUCT_STATUS:
      return {
        ...state,
        updateProductStatus: {
          loading: true,
        },
      };
    case c.UPDATE_PRODUCT_STATUS_SUCCESS:
      return {
        ...state,
        updateProductStatus: {
          loading: false,
          fail: false,
        },
      };
    case c.UPDATE_PRODUCT_STATUS_FAIL:
      return {
        ...state,
        updateProductStatus: {
          loading: false,
          fail: true,
        },
      };
    default:
      return state;
  }
};
