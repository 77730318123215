import React, { useState } from "react";
import { Table, Button, Empty } from "antd";
import "../ReportsTable/style.css";
import { cpfMask } from "../../utils/cpfMask";
import * as styles from "./theme/index.module.scss";
import {
  acceptUndervWallet,
  rejectUndervWallet,
  fetchUndervWallet,
} from "../../actions/RepAction/actions";
import phoneMask from "../../utils/phoneMask";
const RepTable = (props) => {
  var formatArray = [];
  for (var i = 0; i < props.repData.data.length; i++) {
    var formatObject = {
      key: props.repData.data[i].id,
      client_code:
        props.repData.data[i].clientPerson?.code ||
        props.repData.data[i].clientPreRegistration?.code,
      client_name:
        props.repData.data[i].clientPerson?.name ||
        props.repData.data[i].clientPreRegistration?.name,
      client_phone:
        props.repData.data[i].clientPerson?.phone_number ||
        props.repData.data[i].clientPreRegistration?.phone_number,
      client_cnpj_cpf:
        props.repData.data[i].clientPerson?.cnpj_cpf ||
        props.repData.data[i].clientPreRegistration?.cnpj_cpf,
      rep_company_id: props.repData.data[i].rep.company_id,
      rep_name: props.repData.data[i].rep.name,
      rep_phone: props.repData.data[i].rep.phone_number,
      rep_cnpj_cpf: props.repData.data[i].rep.cnpj_cpf,
    };
    formatArray.push(formatObject);
  }

  const columns = [
    {
      title: "Nome(Representante)",
      dataIndex: "rep_name",
    },
    {
      title: "CPF(Representante)",
      dataIndex: "rep_cnpj_cpf",
      render: (cpf_cnpj) => <span>{cpfMask(cpf_cnpj)}</span>,
    },
    {
      title: "Código Empresa (Representante)",
      dataIndex: "rep_company_id",
    },
    {
      title: "Telefone (Representante)",
      dataIndex: "rep_phone",
      render: (phone_mask) => <span>{phoneMask(phone_mask)}</span>,
    },

    {
      title: "Nome (Cliente)",
      dataIndex: "client_name",
    },
    {
      title: "CPF(Cliente)",
      dataIndex: "client_cnpj_cpf",
      render: (cpf_cnpj) => <span>{cpfMask(cpf_cnpj)}</span>,
    },
    {
      title: "Matrícula (Cliente)",
      dataIndex: "client_code",
    },
    {
      title: "Telefone (Cliente)",
      dataIndex: "client_phone",
      render: (phone_mask) => <span>{phoneMask(phone_mask)}</span>,
    },
    {
      title: "Aceitar | Recusar",
      render: (data) => {
        return (
          <div className={styles.actionButtonContainer}>
            <Button
              type="primary"
              onClick={() => {
                props.dispatch(async () => {
                  await acceptUndervWallet(data.key)(props.dispatch);
                  await fetchUndervWallet()(props.dispatch);
                });
              }}
              style={{
                backgroundColor: "green",
                border: "none",
                marginRight: "5px",
              }}
            >
              Aceitar
            </Button>
            <Button
              type="primary"
              style={{
                backgroundColor: "#f22",
                border: "none",
              }}
              onClick={() => {
                props.dispatch(async () => {
                  await rejectUndervWallet(data.key)(props.dispatch);
                  await fetchUndervWallet()(props.dispatch);
                });
              }}
            >
              Recusar
            </Button>
          </div>
        );
      },
    },
  ];
  return (
    <Table
      loading={props.loading}
      columns={columns}
      dataSource={formatArray}
      style={{
        width: "100%",
        minWidth: "100%",
        overflow: "auto",
        borderRadius: 10,
        fontFamily: "Kostic_Roc_Grotesk_Regular",
      }}
      locale={{
        emptyText: (
          <Empty description="Sem carteiras para serem aceitas no momento" />
        ),
      }}
      className={"RCM_two_level_table1"}
      scrollToFirstRowOnChange={true}
    />
  );
};
export default RepTable;
