import * as c from "./types";
import Api from "../../api/api";

export const fetchOrders = (
  page,
  exportBool,
  status,
  code,
  branch,
  year
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: c.FETCH_ORDERS,
    });
    const res = await Api.get(
      `admin/order/all?perpage=10&page=${page}&branch=${branch}&code=${code}&status=${status}&exported=${exportBool}&year=${year}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    if (res.data) {
      dispatch({
        type: c.FETCH_ORDERS_SUCCESS,
        payload: {
          data: res.data.data,
          pagination: res.data.pagination,
        },
      });
    } else {
      dispatch({
        type: c.FETCH_ORDERS_FAIL,
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: c.FETCH_ORDERS_FAIL,
    });
  }
};

export const changeOrderStatus = (orderid, status) => async (dispatch) => {
  try {
    dispatch({
      type: c.CHANGE_ORDER_STATUS,
    });
    const res = await Api.post(
      "admin/order/status",
      {
        order_id: orderid,
        status: status,
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    if (res.status === 200) {
      dispatch({
        type: c.CHANGE_ORDER_STATUS_SUCCESS,
      });
    } else {
      dispatch({
        type: c.CHANGE_ORDER_STATUS_FAIL,
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: c.CHANGE_ORDER_STATUS_FAIL,
    });
  }
};
