import { FETCH_CATEGORIES, FETCH_CATEGORIES_SUCCESS, FETCH_CATEGORIES_FAIL } from './types';
import Api from '../../api/api';

export const fetchCategories = ({ category }) => (dispatch, getState) => {
    try {
        dispatch({
            type: FETCH_CATEGORIES,
        })
        if(!category) throw new Error('undefined category')
        
        Api.get(`admin/typeproduct/list?type_product_id=${category}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }).then(res => {
            if (res.data) {
                dispatch({
                    type: FETCH_CATEGORIES_SUCCESS,
                    payload: {
                        data: res.data
                    }
                })
            } else {
                dispatch({
                    type: FETCH_CATEGORIES_FAIL,
                })
            }
        }).catch(err => {
            console.log(err)
            dispatch({
                type: FETCH_CATEGORIES_FAIL,
            })
        })
    } catch (error) {
        dispatch({
            type: FETCH_CATEGORIES_FAIL,
        })
    }
}