import { Layout, Row } from "antd";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { fetchCategories } from "../../actions/Category/actions";
import { fetchMainCategories } from "../../actions/MainCategory/actions";
import { fetchUser } from "../../actions/userFetchAction/userFetchAction";
import CategoryCard from "../../components/CategoryCard";
import Header from "../../components/Header";
import LoadingSpin from "../../components/LoadingSpin";
import MenuItens from "../../components/MenuItens";

function Categories(props) {
  const { Content, Sider } = Layout;
  const { history, match } = props;
  const { params } = match;

  const state = useSelector((state) => state.user.user);
  const [collapsed, setCollapsed] = useState(false);
  useEffect(() => {
    props.dispatch(fetchCategories(params));
    props.dispatch(fetchMainCategories());
    props.dispatch(fetchUser());
  }, [props, params]);

  const { typeCategory, categories, loading, fail } = useSelector(
    (state) => state.categories
  );

  const previousUrl = `/products`;
  return (
    <>
      <Layout>
        <Sider
          collapsed={collapsed}
          collapsible
          collapsedWidth="0"
          trigger={null}
          style={{
            height: "100%",
            backgroundColor: "white",
            width: "auto",
          }}
        >
          <MenuItens />
        </Sider>
        <Layout
          className="site-layout"
          style={{
            background: "#001529",
            minHeight: "100vh",
            zIndex: "999",
            minWidth: "100vh",
          }}
        >
          <Header
            name={state ? state.name : null}
            pageName={"Categorias principais"}
            secondDiv={typeCategory ? typeCategory.name : ""}
            view={true}
            history={history}
            previousUrl={previousUrl}
            collapsed={[collapsed, setCollapsed]}
          />
          <Content
            style={{ margin: "24px 16px 0", justifyContent: "space-around" }}
          >
            <LoadingSpin loading={loading}>
              <Row gutter={[16, 24]}>
                {categories.map((item) => (
                  <CategoryCard
                    key={item.id}
                    url={`${previousUrl}/${params.category}/${item.id}`}
                    name={item.name}
                    image={item.url}
                    history={history}
                    style={{
                      maxWidth: "300px",
                      minHeight: "350px",
                      span: "6",
                      imgHeight: "100px",
                    }}
                  ></CategoryCard>
                ))}
              </Row>
            </LoadingSpin>
          </Content>
        </Layout>
      </Layout>
    </>
  );
}

export default connect()(Categories);
