import { Layout, Row } from "antd";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { fetchCategory } from "../../actions/CategoryId/actions";
import { fetchProducts } from "../../actions/Product/actions";
import { fetchUser } from "../../actions/userFetchAction/userFetchAction";
import Header from "../../components/Header";
import LoadingSpin from "../../components/LoadingSpin";
import MenuItens from "../../components/MenuItens";
import ProductCard from "./../../components/ProductCard";

function Products(props) {
  const { Content, Sider } = Layout;
  const { history, match } = props;
  const { params } = match;
  const state = useSelector((state) => state.user.user);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    props.dispatch(fetchProducts(params));
    props.dispatch(fetchUser());
    props.dispatch(fetchCategory({ category: params.category }));
  }, [props, params]);
  const { category, loading: loadingCategory } = useSelector(
    (state) => state.category
  );
  const { typeProduct, products, loading } = useSelector(
    (state) => state.products
  );

  return (
    <>
      <Layout>
        <Sider
          onCollapse={() => {
            setCollapsed(!collapsed);
          }}
          collapsed={collapsed}
          collapsible
          collapsedWidth="0"
          trigger={null}
          style={{
            height: "100%",
            backgroundColor: "white",
            width: "auto",
          }}
        >
          <MenuItens />
        </Sider>
        <Layout
          className="site-layout"
          style={{
            background: "#001529",
            minHeight: "100vh",
            zIndex: "999",
            minWidth: "100vh",
          }}
        >
          <Header
            name={state ? state.name : null}
            pageName={"Categorias principais"}
            thirdDiv={typeProduct ? typeProduct.name : ""}
            view={true}
            previousUrl={`/products/${params.category}`}
            secondDiv={category ? category.name : ""}
            history={history}
            collapsed={[collapsed, setCollapsed]}
          />
          <Content
            style={{ margin: "24px 16px 0", justifyContent: "space-around" }}
          >
            <LoadingSpin loading={loading}>
              <Row gutter={[16, 24]}>
                {products.map((item) => (
                  <ProductCard
                    key={item.id}
                    name={item.name}
                    phone_number={item.phone_number}
                    image={item.url}
                    price={item.price}
                    history={history}
                    id={item.id}
                    quantity={item.quantity_kg}
                    massUnit={item.mass_unit}
                    isAvailable={item.is_available}
                    style={{ maxWidth: "300px", minHeight: "400px", span: "6" }}
                  ></ProductCard>
                ))}
              </Row>
            </LoadingSpin>
          </Content>
        </Layout>
      </Layout>
    </>
  );
}

export default connect()(Products);
