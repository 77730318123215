import { Button, Layout, Popconfirm, Row } from "antd";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { fetchMainCategories } from "../../actions/MainCategory/actions";
import { updateProductStatus } from "../../actions/Product/actions";
import { fetchUser } from "../../actions/userFetchAction/userFetchAction";
import AddProductModal from "../../components/AddProductModal";
import CategoryCard from "../../components/CategoryCard/index";
import Header from "../../components/Header";
import LoadingSpin from "../../components/LoadingSpin";
import MenuItens from "../../components/MenuItens";
function MainCategories(props) {
  const { Content, Sider } = Layout;
  const dispatch = useDispatch();
  const { history } = props;
  const [collapsed, setCollapsed] = useState(false);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    props.dispatch(fetchMainCategories());
    props.dispatch(fetchUser());
  }, [props]);
  const state = useSelector((state) => state.user.user);
  const { mainCategories, loading } = useSelector(
    (state) => state.mainCategories
  );

  return (
    <>
      <Layout>
        <Sider
          onCollapse={() => {
            setCollapsed(!collapsed);
          }}
          collapsed={collapsed}
          collapsible
          collapsedWidth="0"
          trigger={null}
          style={{
            height: "100%",
            backgroundColor: "white",
            width: "auto",
          }}
        >
          <MenuItens />
        </Sider>
        <Layout
          className="site-layout"
          style={{
            background: "#001529",
            minHeight: "100vh",
            zIndex: "999",
            minWidth: "100vh",
          }}
        >
          <Header
            name={state ? state.name : null}
            pageName={"Categorias principais"}
            history={history}
            collapsed={[collapsed, setCollapsed]}
          />
          <LoadingSpin loading={loading}>
            <Content
              style={{
                margin: "24px 16px 0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 15,
                }}
              >
                <div>
                  <Popconfirm
                    title="Tem certeza que deseja desabilitar todos os produtos?"
                    okText="Sim, desabilitar!"
                    cancelText="Não, cancelar!"
                    onConfirm={() => {
                      dispatch(updateProductStatus({ disabled: true }));
                    }}
                  >
                    <Button
                      type="primary"
                      size="medium"
                      style={{
                        backgroundColor: "#31924a",
                        border: "none",
                        fontFamily: "Kostic_Roc_Grotesk_Regular",
                      }}
                      onClick={() => {
                        dispatch(updateProductStatus({ disabled: true }));
                      }}
                    >
                      Desabilitar todos produtos
                    </Button>
                  </Popconfirm>
                  <Popconfirm
                    title="Tem certeza que deseja habilitar todos os produtos?"
                    okText="Sim, habilitar!"
                    cancelText="Não, cancelar!"
                    onConfirm={() => {
                      dispatch(updateProductStatus({ disabled: false }));
                    }}
                  >
                    <Button
                      type="primary"
                      size="medium"
                      style={{
                        backgroundColor: "#31924a",
                        border: "none",
                        fontFamily: "Kostic_Roc_Grotesk_Regular",
                        margin: 5,
                      }}
                    >
                      Habilitar todos produtos
                    </Button>
                  </Popconfirm>
                </div>
                <Button
                  type="primary"
                  size="medium"
                  style={{
                    backgroundColor: "#31924a",
                    border: "none",
                    fontFamily: "Kostic_Roc_Grotesk_Regular",
                  }}
                  onClick={() => {
                    setVisible(!visible);
                  }}
                >
                  Adicionar Produto
                </Button>
              </div>
              <AddProductModal
                visibility={[visible, setVisible]}
                dispatch={props.dispatch}
              />

              <Row gutter={[16, 24]}>
                {mainCategories.map((item) => (
                  <CategoryCard
                    key={item.id}
                    url={`/products/${item.id}`}
                    name={item.name}
                    image={item.url}
                    history={history}
                    style={{
                      maxWidth: "450px",
                      minHeight: "350px",
                      span: "12",
                      imgHeight: "150px",
                    }}
                  ></CategoryCard>
                ))}
              </Row>
            </Content>
          </LoadingSpin>
        </Layout>
      </Layout>
    </>
  );
}

export default connect()(MainCategories);
