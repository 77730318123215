import React, { useState } from 'react';
import { Layout, Button } from 'antd';
import {
  LogoutOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  RedoOutlined,
} from '@ant-design/icons';

import { logoutUser } from '../../actions/authActions/authActions';
import { connect } from 'react-redux';
import * as styles from './index.module.scss';
const Header = (props) => {
  const [loadingButtom, setLoadingButtom] = useState(false)
  const { Header } = Layout;
  const { previousUrl } = props;
  
  return (
    <Header style={{ padding: 0, background: '#01101f' }}>
      <div className={styles.pageName}>
        <div className={styles.siderTrigger}>
          {props.collapsed[0] ? (
            <MenuUnfoldOutlined
              onClick={() => props.collapsed[1](!props.collapsed[0])}
            />
          ) : (
            <MenuFoldOutlined
              onClick={() => props.collapsed[1](!props.collapsed[0])}
            />
          )}
        </div>
        <span>
          <span
            style={{ textDecoration: 'none', color: 'white', cursor: 'pointer'}}
            onClick={() => props.secondDiv ? props.history.replace('/products') : null}
          >
            {props.pageName}
          </span>
          {/*<RedoOutlined style={{marginLeft: 15}} />*/}
          <span
            style={{ margin: '0',textDecoration: 'none', color: 'white', cursor: 'pointer'}}
            onClick={() => props.thirdDiv ? props.history.replace(previousUrl) : null}
          >
            {props.view ? '/' + props.secondDiv + '/' : null}{' '}
          </span>
          <span
            style={{ margin: '0',textDecoration: 'none', color: 'white'}}
          >
            {props.thirdDiv ? props.thirdDiv : null}
          </span>
        </span>
      </div>
      <div className={styles.headerAvatar}>
        <span style={{ color: 'white', fontWeight: 'bold' }}>
          Olá{' '}
          <span style={{ color: '#37724a', marginLeft: 5 }}>
            {props.name ? props.name : 'Usuário'}
          </span>
        </span>
      </div>
      <div className={styles.headerNotification}>
        <LogoutOutlined
          style={{ color: 'white', fontSize: 15 }}
          onClick={() => props.dispatch(logoutUser())}
        />
      </div>
    </Header>
  );
};

export default connect()(Header);
