import {
    AUTH_ERROR,
    AUTH_SUCCESS,
    AUTH,
    LOGOUT

} from '../../actions/authActions/types'

const initialState = {
    token: localStorage.getItem('token'),
    authenticated: localStorage.getItem('token') ? true : null,
    loading: false,
    fail: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case AUTH:
            return {
                ...state,
                loading: true,
            };
        case AUTH_SUCCESS:
            return {
                ...state,
                authenticated: true,
                loading: false,
                token: action.payload.token,
            };
        case AUTH_ERROR:
            return {
                authenticated: false,
                loading: false,
                fail: true,
            };
        case LOGOUT: {
            return {
                ...state,
                authenticated: false,
                token: null,
            }
        }
        default: {
            return {
                ...state
            }
        }

    }
}