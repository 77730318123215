import React, { useState } from "react";
import { Modal, Input, Checkbox } from "antd";
import * as styles from "./index.module.scss";
import fixDecimalPlace from "../../utils/productPriceFormatter";
import { connect } from "react-redux";
import { updateProduct } from "../../actions/productUpdate/actions";
function ProductModal({
  id,
  img,
  productName,
  productPrice,
  description,
  dispatch,
  isAvailable,
}) {
  const [visible, setVisibility] = useState(false);
  const [actualProductname, setActualName] = useState(productName);
  const [actualProductprice, setActualPrice] = useState(
    fixDecimalPlace(productPrice)
  );
  const [is_available, setIsAvailable] = useState(isAvailable);

  const handleSubmit = () => {
    let formattedPrice = actualProductprice.replace(",", "").replace(".", "");
    let payload = {
      id,
      name: actualProductname,
      price: formattedPrice,
      is_available: is_available,
    };
    dispatch(updateProduct(payload));
  };
  return (
    <>
      <span className={styles.topSpan} onClick={() => setVisibility(!visible)}>
        ...
      </span>
      <Modal
        title={`Detalhes do produto ${productName}`}
        visible={visible}
        onOk={() => handleSubmit()}
        onCancel={() => setVisibility(!visible)}
        style={{
          background: "#000c17 ",
          fontFamily: "Kostic_Roc_Grotesk_Regular",
        }}
      >
        <div className={styles.cardContainer}>
          <div className={styles.cardImage}>
            <span style={{ color: "white", fontSize: 20 }}>{productName}</span>
          </div>
          <div className={styles.inputsContainer}>
            <div className={styles.inputs}>
              <Input
                style={{
                  minWidth: "200px",
                  maxWidth: "500px",
                  marginBottom: 5,
                }}
                size="large"
                value={actualProductname}
                onChange={(e) => setActualName(e.target.value)}
              />
              <Input
                style={{
                  minWidth: "200px",
                  maxWidth: "500px",
                  marginBottom: 5,
                }}
                size="large"
                value={"R$ " + actualProductprice}
                onChange={(e) =>
                  setActualPrice(fixDecimalPlace(e.target.value))
                }
              />
              <Checkbox
                checked={is_available}
                onChange={() => setIsAvailable(!is_available)}
              >
                <span style={{ color: "white", fontSize: 20 }}>Disponível</span>
              </Checkbox>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default connect()(ProductModal);
