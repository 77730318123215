import React, { useState } from "react";
import phoneMask from "../../utils/phoneMask";
import { Modal, Button, Card } from "antd";
import * as styles from "./index.module.scss";

export default function TableModal({ rowData }) {
  const [visibility, setVisibility] = useState(false);
  const gridStyle = {
    width: "50%",
    textAlign: "center",
  };
  const modalHandler = () => {
    setVisibility(!visibility);
  };
  const { limit_month } = rowData;
  const months = [
    "jun",
    "jul",
    "ago",
    "set",
    "out",
    "nov",
    "dez",
    "jan",
    "fev",
    "mar",
    "abr",
  ];
  return (
    <>
      <Button type="primary" onClick={() => modalHandler()}>
        Ver mais
      </Button>
      <Modal
        title={`Detalhes do pedido do cliente: ${rowData.name}`}
        visible={visibility}
        onOk={() => setVisibility(!visibility)}
        onCancel={() => setVisibility(!visibility)}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <Card title={`Entrega do produto ${rowData.product_name}`}>
          {Object.entries(rowData.months).map(([month, value]) =>
            months.indexOf(limit_month) >= months.indexOf(month) ? (
              <Card.Grid key={month} style={gridStyle}>
                {`${month}`}:{" "}
                {`${value ? value / 1000 : 0} kg | ${
                  value ? value / rowData.masss_unit_quantity : 0
                } SC`}
              </Card.Grid>
            ) : null
          )}
        </Card>
        ,
        <div className={styles.userInfo}>
          <div className={styles.cardInfo}>
            <span style={{ textAlign: "center", display: "flex" }}>
              Informações do comprador
            </span>
          </div>
          <div className={styles.cardBody}>
            <span>
              Nome: <strong>{rowData.name}</strong>
            </span>
            <span style={{ marginTop: 5 }}>
              Email: <strong>{rowData.email}</strong>
            </span>
            <span style={{ marginTop: 5 }}>
              {rowData.cnpj_cpf.length > 14 ? "CNPJ:" : "CPF:"}{" "}
              <strong>{rowData.cnpj_cpf} </strong>{" "}
            </span>
            <span style={{ marginTop: 5 }}>
              Celular: <strong>{phoneMask(rowData.phone_number)} </strong>{" "}
            </span>
            <span>
              <strong>
                {`Local de retirada: ${
                  rowData.withdrawal_location_id === 1
                    ? "Loja Agropecuária"
                    : "Polo Industrial"
                }`}
              </strong>
            </span>
          </div>
        </div>
      </Modal>
    </>
  );
}
