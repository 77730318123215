import React from 'react';
import { Col } from 'antd';
import * as styles from './index.module.scss';
import defaultImage from '../../assets/default.png';
import loading from '../../assets/loading(1125x750).gif';
import Img from 'react-image';

export default function CategoryCard({ url, name, image, history, style }) {
  return (
    <Col
      className="gutter-row"
      style={{ cursor: 'pointer' }}
      span={style.span}
      onClick={() => {
        history.push(url);
      }}
    >
      <div
        style={{ background: '#1b203a', padding: '8px', borderRadius: '12px' }}
      >
        <div className={styles.headerLogo}>
          <Img
            alt={name}
            src={[image, defaultImage]}
            loader={<img alt={name} src={loading} />}
            className={styles.headerLogoImg}
            style={{ maxWidth: style.maxWidth }}
          />
        </div>
        <div className={styles.titleContainer}>
          <span>{name}</span>
        </div>
      </div>
    </Col>
  );
}
