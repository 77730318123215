import { Button, Input, Layout, notification, Select } from "antd";
import { CSVDownload } from "export-artesanato";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { exportOrders } from "../../actions/ExportOrders/actions";
import { fetchOrders } from "../../actions/Order/actions";
import { fetchUser } from "../../actions/userFetchAction/userFetchAction";
import Header from "../../components/Header";
import LoadingSpin from "../../components/LoadingSpin";
import MenuItens from "../../components/MenuItens";
import ReportsTable from "../../components/ReportsTable";
import maskCode from "../../utils/maskCode";
import * as styles from "./index.module.scss";
import selectedHeaders from "./selectedHeaders";

function Reports(props) {
  const { Option } = Select;
  const { Content, Sider } = Layout;
  const [filteredData, setfData] = useState([]);
  const [selectedData, setSelected] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [code, setCode] = useState("");
  const [branch, setBranch] = useState("");
  const [exportOption, setExportOption] = useState("");
  const [status, setStatus] = useState("");
  const [selectedYear, setYear] = useState(moment().format("YYYY"));
  const [successAction, setSuccessAction] = useState(false);
  const state = useSelector((state) => state.user.user);
  const data = useSelector((state) => state.orders);
  const exportedData = useSelector((state) => state.exportedOrders.data);
  const exportLoading = useSelector((state) => state.exportedOrders.loading);
  const pagination = useSelector((state) => state.orders.pagination);
  const [alreadyExported, setAlreadyExported] = useState(true);

  useEffect(() => {
    props.dispatch(fetchOrders(1));
    props.dispatch(fetchUser());
  }, [props]);

  useEffect(() => {
    if (data.orderStatus.fail)
      notification.error({ message: "Algo de errado aconteceu!" });
    if (data.orderStatus.loading) setSuccessAction(true);
    if (!data.orderStatus.loading && successAction && !data.orderStatus.fail) {
      notification.success({ message: "Sucesso!" });
      setSuccessAction(false);
    }
  }, [data.orderStatus.fail, data.orderStatus.loading, successAction]);

  useEffect(() => {
    props.dispatch(
      fetchOrders(1, exportOption, status, code, branch, selectedYear)
    );
  }, [branch, code, exportOption, props, status, selectedYear]);

  const branchFilter = (branchId) => {
    setBranch(branchId);
  };
  const statusFilter = (pName) => {
    setStatus(pName);
  };
  const exportFilter = (exportBool) => {
    setExportOption(exportBool);
  };

  return (
    <>
      <Layout>
        <Sider
          onCollapse={() => {
            setCollapsed(!collapsed);
          }}
          collapsed={collapsed}
          collapsible
          collapsedWidth="0"
          trigger={null}
          style={{
            height: "100%",
            backgroundColor: "white",
            width: "auto",
          }}
        >
          <MenuItens />
        </Sider>
        <Layout
          className="site-layout"
          style={{
            justifyContent: "center",
            background: "#001529",
            minHeight: "100vh",
            zIndex: 999,
          }}
        >
          <Header
            name={state ? state.name : null}
            pageName={"Contratos"}
            collapsed={[collapsed, setCollapsed]}
          />
          <Content style={{ justifyContent: "space-around", padding: 24 }}>
            <LoadingSpin loading={data.orders == null}>
              <div className={styles.bContainer}>
                <div className={styles.filterContainer}>
                  <Select
                    showSearch
                    style={{ width: 200, marginRight: 10 }}
                    placeholder={"Filtrar pelo polo "}
                    optionFilterProp="children"
                    onChange={branchFilter}
                  >
                    <Option value="">Todos</Option>
                    <Option key={1} value={"010201"}>
                      Loja Agropecuária
                    </Option>
                    <Option key={2} value={"010401"}>
                      Polo Industrial
                    </Option>
                    ))
                  </Select>
                  <Input
                    value={code}
                    style={{ width: 200, marginRight: 10 }}
                    placeholder={"Filtrar pela matrícula"}
                    onChange={(e) => setCode(maskCode(e.target.value))}
                  />
                  <Select
                    showSearch
                    style={{ width: 200, marginRight: 10 }}
                    placeholder={"Filtrar por status"}
                    optionFilterProp="children"
                    onChange={statusFilter}
                  >
                    <Option value="">Nenhum</Option>
                    <Option key={"1"} value={"pending"}>
                      Pendente
                    </Option>
                    <Option key={"2"} value={"denied"}>
                      Negados
                    </Option>
                    <Option key={"3"} value={"approved"}>
                      Aprovados
                    </Option>
                  </Select>
                  <Select
                    showSearch
                    style={{ width: 200, marginRight: 10 }}
                    placeholder={"Filtrar por exportação"}
                    optionFilterProp="children"
                    onChange={exportFilter}
                  >
                    <Option value="">Nenhum</Option>
                    <Option key={"1"} value={"true"}>
                      Exportados
                    </Option>
                    <Option key={"2"} value={"false"}>
                      Não exportados
                    </Option>
                  </Select>

                  <Select
                    showSearch
                    style={{ width: 200, marginRight: 10 }}
                    placeholder={"Filtrar por ano"}
                    optionFilterProp="children"
                    value={selectedYear}
                    onChange={(e) => setYear(e)}
                  >
                    <Option value="">Nenhum</Option>

                    <Option key={"1"} value={"2020"}>
                      2020
                    </Option>

                    <Option key={"2"} value={"2021"}>
                      2021
                    </Option>

                    <Option key={"3"} value={"2022"}>
                      2022
                    </Option>
                  </Select>
                </div>
                <div className={styles.exportContainer}>
                  <Button
                    style={{
                      background: "#37724a",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textTransform: "uppercase",
                      fontFamily: "Kostic_Roc_Grotesk_Regular",
                      border: "none",
                      marginTop: 5,
                    }}
                    onClick={() => {
                      setAlreadyExported(false);
                      props.dispatch(exportOrders(selectedData, false));
                    }}
                  >
                    {`Exportar Selecionados (${selectedData.length})`}
                  </Button>
                  <Button
                    style={{
                      background: "#37724a",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textTransform: "uppercase",
                      fontFamily: "Kostic_Roc_Grotesk_Regular",
                      border: "none",
                      marginLeft: 10,
                      marginTop: 5,
                    }}
                    onClick={() => {
                      setAlreadyExported(false);
                      props.dispatch(exportOrders([1], true));
                    }}
                  >
                    Exportar todos
                  </Button>

                  {exportLoading === false &&
                  exportedData !== null &&
                  !alreadyExported ? (
                    <CSVDownload
                      setAlreadyExported={setAlreadyExported}
                      separator={";"}
                      style={{
                        backgroundColor: "#37724a",
                        color: "white",
                        height: 40,
                        padding: 20,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textTransform: "uppercase",
                        fontFamily: "Kostic_Roc_Grotesk_Regular",
                        marginRight: 15,
                        marginLeft: 10,
                      }}
                      nameBlob={`Ordens_${new Date().toISOString()}.csv`}
                      headers={selectedHeaders}
                      data={exportedData}
                      target="_blank"
                    >
                      Baixar CSV
                    </CSVDownload>
                  ) : null}
                </div>
              </div>
              <div
                className={styles.reportsContinaer}
                style={{ justifyContent: "center" }}
              >
                {data.orders === null ? (
                  <ReportsTable
                    receivedData={[]}
                    dispatch={props.dispatch}
                    pagination={pagination}
                    year={selectedYear}
                  />
                ) : (
                  <ReportsTable
                    loading={data.loading}
                    dispatch={props.dispatch}
                    receivedData={
                      filteredData.length > 0 ? filteredData : data.orders
                    }
                    year={selectedYear}
                    filterOptions={{ exportOption, status, code, branch }}
                    setData={[selectedData, setSelected]}
                    pagination={pagination}
                  />
                )}
              </div>
            </LoadingSpin>
          </Content>
        </Layout>
      </Layout>
    </>
  );
}

export default connect()(Reports);
