import Axios from "axios";
/*
    baseURL: 'https://cooprata-release.herokuapp.com/',
*/
let Api = Axios.create({
  baseURL: "https://cooprata-app.herokuapp.com/",
});

Api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      window.localStorage.clear();
      window.location.reload();
    } else {
      return error;
    }
  }
);

export default Api;
