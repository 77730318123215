export const FETCH_CLIENTS = 'FETCH_CLIENTS';
export const FETCH_CLIENTS_FAIL = 'FETCH_CLIENTS_FAIL';
export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS';
export const DELETE_CLIENTS = 'DELETE_CLIENTS';
export const DELETE_CLIENTS_SUCCESS = 'DELETE_CLIENTS_SUCCESS';
export const DELETE_CLIENTS_FAIL = 'DELETE_CLIENTS_FAIL';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT_FAIL = 'UPDATE_CLIENT_FAIL';
export const FETCH_ALL_CLIENTS = 'FETCH_ALL_CLIENTS';
export const FETCH_ALL_CLIENTS_FAIL = 'FETCH_CLIENTS_FAIL';
export const FETCH_ALL_CLIENTS_SUCCESS = 'FETCH_ALL_CLIENTS_SUCCESS';
export const FR_FETCH_CLIENTS = 'FR_FETCH_CLIENTS';
export const FR_FETCH_CLIENTS_FAIL = 'FR_FETCH_CLIENTS_FAIL';
export const FR_FETCH_CLIENTS_SUCCESS = 'FR_FETCH_CLIENTS_SUCCESS';
export const ACCEPT_CLIENT = 'ACCEPT_CLIENT';
export const ACCEPT_CLIENT_FAIL = 'ACCEPT_CLIENT_FAIL';
export const ACCEPT_CLIENT_SUCCESS = 'ACCEPT_CLIENT_SUCCESS';
export const CREATE_CLIENT = 'CREATE_CLIENT';
export const CREATE_CLIENT_FAIL = 'CREATE_CLIENT_FAIL';
export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS';
