import React from 'react';
import { Col, Checkbox } from 'antd';
import * as styles from './index.module.scss';
import fixDecimalPlace from '../../utils/productPriceFormatter';
import fixDecimalNumber from '../../utils/fixDecimalNumber';
import defaultImage from '../../assets/default.png';
import loading from '../../assets/loading.gif';
import Img from 'react-image';

import ProductModal from '../ProductModal';
export default function CategoryCard({
  url,
  name,
  description,
  price,
  image,
  history,
  style,
  id,
  quantity,
  massUnit,
  isAvailable
}) {
  return (
    <Col className="gutter-row" span={style.span}>
      <div
        style={{
          background: '#1b203a',
          padding: '8px',
          borderRadius: '12px',
          minHeight: style.minHeight,
        }}
      >
        <div className={styles.topGrid}>
          <ProductModal
            img={image}
            productName={name}
            productPrice={price}
            id={id}
            description={description}
            isAvailable={isAvailable}
          />
        </div>
        <div className={styles.titleContainer}>
          <span
            onClick={() => url ? history.push(url) : "" }
          >
            {name}
          </span>
        </div>
        <div className={styles.headerLogo}>
          <Img
            alt={name}
            src={image ? image : defaultImage}
            loader={<img alt={name} src={loading} />}
            className={styles.headerLogoImg}
            style={{ maxWidth: style.maxWidth }}
          />
        </div>
        <div className={styles.bodyText}>
          <br />
          <strong>Preço: </strong>
          <span>R$ {fixDecimalNumber(fixDecimalPlace(price))}</span> <br />
          <strong>Quantidade: {quantity} KG</strong> <br />
          <strong>Unidade: {massUnit.toUpperCase()}</strong>
          <Checkbox checked={isAvailable} disabled={true}><strong>Disponível</strong></Checkbox>
        </div>
      </div>
    </Col>
  );
}
