import * as c from '../../actions/Company/types';

const INITIAL_STATE = {
    loading: false,
    fail: false,
    data: []
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case c.FETCH_COMPANY:
            return {
            ...state,
            loading: true,
            };
        case c.FETCH_COMPANY_SUCCESS:
            return {
            ...state,
            loading: false,
            data: action.payload.data,
            };
        case c.FETCH_COMPANY_FAIL:
            return {
            ...state,
            loading: false,
            fail: true,
            };
        default:
            return state;
    }
}