import React from "react";
import { Table, Tag, Button, Popconfirm, Empty } from "antd";
import phoneMask from "../../utils/phoneMask";
import { cpfMask } from "../../utils/cpfMask";
import {
  deleteClients,
  acceptClient,
  fetchClientsByStatus,
} from "../../actions/Clients/clientsActions";
export default function NewClientsTable(props) {
  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      editable: true,
    },
    {
      title: "CPF/CNPJ",
      dataIndex: "cnpj_cpf",
      render: (cnpj_cpf) => <span>{cpfMask(cnpj_cpf)}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      editable: true,
    },

    {
      title: "Matrícula",
      dataIndex: "code",
      editable: true,
    },
    {
      title: "Telefone",
      dataIndex: "phone_number",
      editable: true,
      render: (record) => <span>{phoneMask(record)}</span>,
    },
    {
      title: "Tipo de usuário",
      dataIndex: "person_type",
      render: (person_type) => (
        <span>
          {person_type === "client" ? (
            <Tag color="green">Agropecuarista</Tag>
          ) : (
            <Tag color="gold">Vendedor</Tag>
          )}
        </span>
      ),
    },
    {
      title: "SMS",
      dataIndex: "validation_status",
      render: (validation_status) => {
        return validation_status === "validation_sms" ? (
          <div
            style={{
              padding: "5px",

              width: "80px",
              height: "auto",
              backgroundColor: "green",
              borderRadius: 5,
              fontSize: "13px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              color: "white",
              fontFamily: "Kostic_Roc_Grotesk_Bold",
            }}
          >
            Validado
          </div>
        ) : (
          <div
            style={{
              padding: "5px",
              width: "80px",
              height: "auto",
              backgroundColor: "#f22",
              textAlign: "center",
              borderRadius: 5,
              fontSize: "12px",
              alignItems: "center",
              display: "flex",
              color: "white",
              fontFamily: "Kostic_Roc_Grotesk_Bold",
            }}
          >
            Agurdando Validação
          </div>
        );
      },
    },
    {
      title: "Aprovar | Recusar",
      render: (rowData) => {
        const handleAccpet = () => {
          props.dispatch( async () => {
           await acceptClient(rowData.cnpj_cpf)(props.dispatch)
           await fetchClientsByStatus(props.pagination.page, "new", true)(props.dispatch)
          })
        }

        const handleDenied = () => {
           props.dispatch( async () => {
             await deleteClients(rowData.id)(props.dispatch)
             await fetchClientsByStatus(props.pagination.page, "new", true)(props.dispatch)
           })
        }

        return (
          <div style={{ display: "flex" }}>
            <Popconfirm
              title="Tem certeza que quer aprovar esse usuário ?"
              onConfirm={handleAccpet}
            >
              <Button
                size="medium"
                type="primary"
                style={{ backgroundColor: "green", border: "none" }}
              >
                Aprovar
              </Button>
            </Popconfirm>
            <Popconfirm
              title="Tem certeza que quer recusar esse usuário ?"
              onConfirm={handleDenied}
            >
              <Button
                size="medium"
                type="danger"
                style={{ border: "none", marginLeft: 5 }}
              >
                Recusar
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const handlePagination = (value) => {
    props.dispatch(fetchClientsByStatus(value, "new", true));
  };

  return (
    <Table
      loading={props.loading}
      columns={columns}
      dataSource={props.data}
      className={"RCM_two_level_table1"}
      scrollToFirstRowOnChange={true}
      rowKey="cnpj_cpf"
      pagination={{
        onChange: handlePagination,
        current: props.pagination.page ? props.pagination.page : 1,
        pageSize: 10,
        total: props.pagination.total ? props.pagination.total : "1",
        showTotal: (total, range) => (
          <span style={{ color: "white" }}>
            {range[0] !== 0 ? range[0] : ""} - {range[1]} de total contratos
          </span>
        ),
      }}
      locale={{
        emptyText: (
          <Empty description="Sem novos clientes disponíveis no momento" />
        ),
      }}
      style={{
        width: "100%",
        minWidth: "100%",
        overflow: "hidden",
        borderRadius: 10,
        fontFamily: "Kostic_Roc_Grotesk_Regular                ",
      }}
    />
  );
}
