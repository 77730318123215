import * as c from "./types";
import Api from "../../api/api";

export const exportOrders = (arrayIds, exportAll) => (dispatch) => {
  dispatch({
    type: c.EXPORT_ORDERS,
  });
  let payload = {
    array_ids: arrayIds,
    fetch_all: exportAll,
  };
  Api.post(`admin/order/export`, payload, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then((res) => {
      if (res.status === 200 && res.data) {
        dispatch({
          type: c.EXPORT_ORDERS_SUCCESS,
          payload: {
            data: res.data,
          },
        });
      } else {
        dispatch({
          type: c.EXPORT_ORDERS_FAIL,
        });
      }
    })
    .catch((err) => {
      if (err) {
        dispatch({
          type: c.EXPORT_ORDERS_FAIL,
        });
      }
    });
};
