import * as c from "../../actions/RepAction/types";

const INITIAL_STATE = {
  fail: false,
  loading: false,
  data: null,
  accept: {
    loading: false,
    fail: false,
    message: null,
  },
  reject: {
    loading: false,
    fail: false,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case c.FETCH_UNDERV_WALLET:
      return {
        ...state,
        loading: true,
      };
    case c.FETCH_UNDERV_WALLET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case c.FETCH_UNDERV_WALLET_FAIL:
      return {
        ...state,
        loading: false,
        fail: true,
      };
    case c.ACCEPT_UNDERV_WALLET:
      return {
        ...state,
        loading: true,
        accept: {
          loading: true,
        },
      };
    case c.ACCEPT_UNDERV_WALLET_SUCCESS:
      return {
        ...state,
        loading: false,
        accept: {
          loading: false,
        },
      };
    case c.ACCEPT_UNDERV_WALLET_FAIL:
      return {
        ...state,
        loading: false,
        accept: {
          loading: false,
          fail: true,
          message: action.payload.message,
        },
      };
    case c.REJECT_UNDERV_WALLET:
      return {
        ...state,
        loading: true,
        reject: {
          loading: true,
        },
      };
    case c.REJECT_UNDERV_WALLET_SUCCESS:
      return {
        ...state,
        loading: false,
        reject: {
          loading: false,
        },
      };
    case c.REJECT_UNDERV_WALLET_FAIL:
      return {
        ...state,
        loading: false,
        reject: {
          loading: false,
          fail: true,
        },
      };

    default:
      return state;
  }
};
