import React from "react";
import { Menu } from "antd";
import * as styles from "./index.module.scss";
import logo from "../../assets/logo.png";
import {
  ShoppingFilled,
  FileTextFilled,
  UserOutlined,
  EditFilled,
  UsergroupAddOutlined,
  ClockCircleFilled,
  WalletOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
export default function MenuItens() {
  const { SubMenu } = Menu;
  return (
    <>
      <div className={styles.headerLogo}>
        <img alt="logo cooprata" src={logo} className={styles.headerLogoImg} />
      </div>
      <Menu
        defaultOpenKeys={[
          window.location.pathname === "/preclients" ||
          window.location.pathname === "/clients" ||
          window.location.pathname === "/newclients"
            ? "sub1"
            : null,
        ]}
        style={{
          background: "transparent",
          color: "#37724a",
          border: "none",
        }}
        mode="inline"
      >
        <Menu.Item
          key="1"
          className={
            window.location.href.indexOf("/products") === -1
              ? null
              : "ant-menu-item-selected"
          }
        >
          <ShoppingFilled />
          <Link to="/products" className={styles.menuItem}>
            <span className="nav-text">Produtos</span>
          </Link>
        </Menu.Item>
        <Menu.Item
          key="2"
          className={
            window.location.href.indexOf("/reports") === -1
              ? null
              : "ant-menu-item-selected"
          }
        >
          <FileTextFilled />
          <Link to="/reports" className={styles.menuItem}>
            <span className="nav-text">Pedidos</span>
          </Link>
        </Menu.Item>

        <SubMenu
          key="sub1"
          style={{ backgroundColor: "#1b203a !important" }}
          title={
            <span className={styles.menuItem}>
              <UserOutlined />
              <span className="nav-text">Clientes</span>
            </span>
          }
        >
          <Menu.ItemGroup>
            <Menu.Item
              key="3"
              className={
                window.location.href.indexOf("/clients") === -1
                  ? null
                  : "ant-menu-item-selected"
              }
            >
              <Link to="/clients" className={styles.menuItem}>
                <span className="nav-text">
                  <EditFilled />
                  Cadastrados
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item
              key="4"
              className={
                window.location.href.indexOf("/preclients") === -1
                  ? null
                  : "ant-menu-item-selected"
              }
            >
              <Link to="/preclients" className={styles.menuItem}>
                <span className="nav-text">
                  <ClockCircleFilled />
                  Pendentes
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item
              key="5"
              className={
                window.location.href.indexOf("/newclients") === -1
                  ? null
                  : "ant-menu-item-selected"
              }
            >
              <Link to="/newclients" className={styles.menuItem}>
                <span className="nav-text">
                  <UsergroupAddOutlined />
                  Novos Clientes
                </span>
              </Link>
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>
        <Menu.Item
          key="6"
          className={
            window.location.href.indexOf("/replist") === -1
              ? null
              : "ant-menu-item-selected"
          }
        >
          <WalletOutlined />
          <Link to="/replist" className={styles.menuItem}>
            <span className="nav-text">Representante</span>
          </Link>
        </Menu.Item>
      </Menu>
    </>
  );
}
