import React, { useState, useEffect } from "react";
import * as styles from "./index.module.scss";
import { Input, Button, notification } from "antd";
import { connect } from "react-redux";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { authUser } from "../../actions/authActions/authActions";
import img from "../../assets/logo.png";
import { cpfMask } from "../../utils/cpfMask";
import { pressEnter } from "../../utils/onKeyPress";

function LoginForm(props) {
  const [cpf_cnpj, setcpfCnpj] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (localStorage.getItem("token")) {
      props.history.replace("/products");
    } else {
      props.history.replace("/");
    }
  }, []);
  const handleSubmit = () => {
    if (!cpf_cnpj || !password) {
      notification.warn({
        message: "Por favor preencha os campos!",
        placement: "topRight",
      });
    } else {
      props.dispatch(authUser(cpf_cnpj, password, props));
    }
  };
  return (
    <div className={styles.backgroundImage}>
      <form className={styles.formContainer}>
        <div className={styles.formLogo}>
          <img src={img} alt="logo cooprata" />
        </div>
        <div className={styles.inputContainer}>
          <Input
            placeholder="CPF"
            style={{ width: 200 }}
            size="large"
            prefix={<UserOutlined />}
            value={cpfMask(cpf_cnpj)}
            maxLength="14"
            onKeyPress={(e) => {
              pressEnter(e, handleSubmit);
            }}
            onChange={(e) => setcpfCnpj(e.target.value)}
          />
          <Input
            placeholder="Senha"
            style={{ width: 200, marginTop: 20 }}
            size="large"
            onKeyPress={(e) => {
              pressEnter(e, handleSubmit);
            }}
            prefix={<LockOutlined />}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
          />
        </div>
        <div className={styles.formBottom}>
          <Button
            size="large"
            style={{ width: 100, backgroundColor: "#37724a", border: "none" }}
            onClick={() => handleSubmit()}
          >
            <span style={{ color: "#fff" }}>Login</span>
          </Button>
        </div>
      </form>
    </div>
  );
}

export default connect()(LoginForm);
