import * as c from '../../actions/Clients/types';

const INITIAL_STATE = {
  data: null,
  loading: false,
  fail: false,

  pagination: {
    data: null,
    loading: false,
    fail: false,
  },
  deleted: {
    loading: false,
    fail: false
  },
  edited: {
    loading: false,
    fail: false,
  },
  accepted: {
    loading: false,
    fail: false,
  },
  created: {
    message: null,
    loading: false,
    fail: false,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case c.FETCH_CLIENTS:
      return {
        ...state,
        loading: true,
        pagination: {
          loading: true,
        },
      };
    case c.FETCH_CLIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        pagination: action.payload.pagination,
      };

    case c.FETCH_CLIENTS_FAIL:
      return {
        ...state,
        loading: false,
        fail: true,
      };
    case c.DELETE_CLIENTS: {
      return {
        ...state,
        loading: true,
        deleted: {
          loading: true,
        },
      };
    }
    case c.DELETE_CLIENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        deleted: {
          loading: false,
        },
      };
    }
    case c.DELETE_CLIENTS_FAIL: {
      return {
        ...state,
        loading: false,
        deleted: {
          loading: false,
          fail: true
        },
      };
    }
    case c.UPDATE_CLIENT: {
      return {
        ...state,
        loading: true,
        edited: {
          loading: true,
          fail: false
        },
      };
    }
    case c.UPDATE_CLIENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        edited: {
          loading: false,
          fail: false,
        },
      };
    }
    case c.UPDATE_CLIENT_FAIL: {
      return {
        ...state,
        loading: false,
        edited: {
          loading: false,
          fail: true,
        },
      };
    }
    case c.ACCEPT_CLIENT: {
      return {
        ...state,
        loading: true,
        accepted: {
          loading: true,
        },
      };
    }
    case c.ACCEPT_CLIENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        accepted: {
          loading: false,
          fail: false,
        },
      };
    }
    case c.ACCEPT_CLIENT_FAIL: {
      return {
        ...state,
        loading: false,
        accepted: {
          loading: false,
          fail: true,
        },
      };
    }
    case c.CREATE_CLIENT: {
      return {
        ...state,
        loading: true,
        created: {
          loading: true,
        },
      };
    }
    case c.CREATE_CLIENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        created: {
          loading: false,
        },
      };
    }
    case c.CREATE_CLIENT_FAIL: {
      return {
        ...state,
        loading: false,
        created: {
          message: action.payload.message,
          loading: false,
          fail: true,
        },
      };
    }
    default:
      return state;
  }
};
