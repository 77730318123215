import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import MenuItens from "../../components/MenuItens";
import RepTable from "../../components/RepTable";
import Header from "../../components/Header";
import LoadingSpin from "../../components/LoadingSpin";
import { Layout, notification } from "antd";
import { fetchUser } from "../../actions/userFetchAction/userFetchAction";
import { fetchUndervWallet } from "../../actions/RepAction/actions";
function RepPage(props) {
  const user = useSelector((state) => state.user);
  const repData = useSelector((state) => state.rep);
  const { loading, accept, reject } = useSelector((state) => state.rep);
  const [collapsed, setCollapsed] = useState(true);
  const { Sider, Content } = Layout;
  const [successReject, setSuccessReject] = useState(false);
  const [successAccepted, setSuccessAccepted] = useState(false);
  useEffect(() => {
    props.dispatch(fetchUser());
    props.dispatch(fetchUndervWallet());
  }, [props]);

  useEffect(() => {
    //edit
    if (accept.fail) notification.error({ message: accept.message });
    if (accept.loading) setSuccessAccepted(true);
    if (!accept.loading && successAccepted && !accept.fail) {
      notification.success({ message: "Carteira aprovada com sucesso!" });
      setSuccessAccepted(false);
    }
  }, [accept.fail, accept.loading, successAccepted]);

  useEffect(() => {
    // deleted
    if (reject.fail)
      notification.error({
        message: "Aconteceu um erro na tentativa de recusar a carteira",
      });
    if (reject.loading) setSuccessReject(true);
    if (!reject.loading && successReject && !reject.fail) {
      notification.success({ message: "Carteira recusada com sucesso!" });
      setSuccessReject(false);
    }
  }, [reject.fail, reject.loading, successReject]);

  return (
    <Layout>
      <Sider
        collapsible
        collapsedWidth="0"
        trigger={null}
        collapsed={collapsed}
        style={{
          height: "100%",
          backgroundColor: "white",
          width: "auto",
        }}
      >
        <MenuItens />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          justifyContent: "center",
          background: "#001529",
          minHeight: "100vh",
          zindex: "999",
        }}
      >
        <Header
          name={user.user ? user.user.name : ""}
          pageName="Representante"
          collapsed={[collapsed, setCollapsed]}
        />
        <Content
          style={{
            justifyContent: "space-around",
            padding: 24,
          }}
        >
          <LoadingSpin loading={repData.data == null}>
            <RepTable
              repData={repData}
              dispatch={props.dispatch}
              loading={loading}
            />
          </LoadingSpin>
        </Content>
      </Layout>
    </Layout>
  );
}

export default connect()(RepPage);
