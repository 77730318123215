import * as c from "../../actions/ExportOrders/types";

const INITIAL_STATE = {
  loading: false,
  fail: false,
  data: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case c.EXPORT_ORDERS:
      return {
        ...state,
        loading: true,
      };
    case c.EXPORT_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case c.EXPORT_ORDERS_FAIL:
      return {
        ...state,
        loading: false,
        fail: true,
      };
    default:
      return state;
  }
};
