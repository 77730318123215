import * as c from '../../actions/Order/types';

const INITIAL_STATE = {
  loading: false,
  fail: false,
  orders: null,
  pagination: null,
  orderStatus: {
    loading: false,
    fail: false,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case c.FETCH_ORDERS:
      return {
        ...state,
        loading: true,
      };
    case c.FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        fail: false,
        orders: action.payload.data,
        pagination: action.payload.pagination,
      };
    case c.FETCH_ORDERS_FAIL:
      return {
        ...state,
        loading: false,
        fail: true,
      };
    case c.CHANGE_ORDER_STATUS: {
      return {
        ...state,
        loading: true,
        orderStatus: {
          loading: true,
          fail: false,
        },
      };
    }
    case c.CHANGE_ORDER_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        orderStatus: {
          loading: false,
          fail: false,
        },
      };
    }
    case c.CHANGE_ORDER_STATUS_FAIL: {
      return {
        ...state,
        loading: false,
        orderStatus: {
          loading: false,
          fail: true,
        },
      };
    }
    default:
      return state;
  }
};
