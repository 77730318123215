export default function fixDecimalPlace(
  number,
  decimalSeparator = ",",
  thousandSeparator = ".",
  decimalPlace = 3
) {
  number = number != null && number !== "" ? number.toString() : "0";
  let numberStr = number.replace(/\D+/g, "");
  numberStr = Number.parseInt(numberStr).toString();

  let numberArr = numberStr.split("");
  let countInt = numberStr.length;
  let tempInt = 0;
  for (let i = 0; i < decimalPlace - countInt; i++) {
    numberArr.unshift("0");
    tempInt++;
  }
  countInt += tempInt;
  let decimal = numberArr.splice(countInt - decimalPlace, Number.MAX_VALUE);
  countInt = numberArr.length;

  let countDecimal = decimal.length;
  let i = -1;
  numberArr = numberArr.map((num) => {
    i++;
    return (countInt - i) % 3 === 0 && i !== 0
      ? `${thousandSeparator}${num}`
      : num;
  });

  if (countInt === 0) numberArr = ["0"];

  return `${numberArr.join("")}${
    countDecimal === 0 ? "" : decimalSeparator
  }${decimal.join("")}`;
}
