import React, { useState, useEffect } from 'react';
import * as styles from './index.module.scss';
import { connect, useSelector } from 'react-redux';
import { Layout } from 'antd';
import MenuItens from '../../components/MenuItens';
import Header from '../../components/Header';
import { fetchUser } from '../../actions/userFetchAction/userFetchAction';
import { fetchallClients } from '../../actions/Clients/clientsActions';
import ClientsTable from '../../components/ClientsTable';
import LoadingSpin from '../../components/LoadingSpin'

function Clients(props) {
  const { Sider, Content } = Layout;
  const [collapsed, setCollapsed] = useState(false);
  const state = useSelector((state) => state.user.user);
  const allClients = useSelector((state) => state.allClients.data);
  const pagination = useSelector((state) => state.allClients.pagination);

  const loading = useSelector((state) => state.loading);
  useEffect(() => {
    props.dispatch(fetchUser());
    props.dispatch(fetchallClients(1));
  }, [props]);
  
  return (
    <Layout>
      <Sider
        collapsed={collapsed}
        collapsible
        collapsedWidth="0"
        trigger={null}
        style={{
          height: '100%',
          backgroundColor: 'white',
          width: 'auto',
        }}
      >
        <MenuItens />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          justifyContent: 'center',
          background: '#001529',
          minHeight: '100vh',
          zIndex: 999,
        }}
      >
        <Header
          name={state ? state.name : null}
          pageName={'Clientes Aprovados'}
          collapsed={[collapsed, setCollapsed]}
        />
        <Content style={{ justifyContent: 'space-around', padding: 24 }}>
          <LoadingSpin loading={loading}>
            <div className={styles.filterContainer}></div>
            <div className={styles.tableContainer}>
              <ClientsTable
                data={allClients}
                dispatch={props.dispatch}
                pagination={pagination ? pagination : ''}
              />
            </div>
          </LoadingSpin>
        </Content>
      </Layout>
    </Layout>
  );
}

export default connect()(Clients);
