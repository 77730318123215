import Api from '../../api/api';
import {
    AUTH,
    AUTH_ERROR,
    AUTH_SUCCESS,
    LOGOUT
} from './types';
import { notification } from 'antd';


export const authUser = (cnpj_cpf, password, props) => (dispatch, getState) => {
    dispatch({ type: AUTH });
    Api.post('admin/login', {
        cnpj_cpf,
        password,
    }).then(res => {
        if (res.data.token) {
            dispatch({
                type: AUTH_SUCCESS,
                payload: {
                    token: res.data.token
                }
            })
            localStorage.setItem('token', res.data.token);
            props.history.replace('/products')
        }
    }).catch(err => {
        if (err) {
            dispatch({
                type: AUTH_ERROR
            })

            notification.error({
                message: 'Login falhou, email ou senha inválidos!',
                placement: 'topRight',
            })

        }
    })
}

export const logoutUser = () => (dispatch, getState) => {
    dispatch({ type: LOGOUT })
    localStorage.removeItem('token');
    window.location.reload();
}