import { Switch, Route, BrowserRouter, Redirect, HashRouter } from "react-router-dom";
import { PrivateRoute } from "./utils/PrivateRoute";
import React from "react";
import Login from "./components/LoginForm";
import Reports from "./modules/Reports";
import MainCategories from "./modules/MainCategories";
import Categories from "./modules/Categories";
import Products from "./modules/Products";
import Clients from "./modules/Clients";
import NewClients from "./modules/NewClients";
import PendingClients from "./modules/PendingClients";
import RepPage from "./modules/RepPage";
export default function Routes() {
  const noMatch = () => {
    return <Redirect to="/" />;
  };
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" component={Login} />
        <PrivateRoute exact path="/reports" component={Reports} />
        <PrivateRoute exact path="/products" component={MainCategories} />
        <PrivateRoute
          exact
          path="/products/:category([0-9]+)"
          component={Categories}
        />{" "}
        <PrivateRoute
          exact
          path="/products/:category([0-9]+)/:product([0-9]+)"
          component={Products}
        />
        <PrivateRoute exact path="/clients" component={Clients} />
        <PrivateRoute exact path="/preclients" component={PendingClients} />
        <PrivateRoute exact path="/newclients" component={NewClients} />
        <PrivateRoute exact path="/replist" component={RepPage} />
        <Route component={noMatch} />
      </Switch>
    </HashRouter>
  );
}
