import * as c from './types';
import Api from '../../api/api';

export const fetchCompanies = () => (dispatch) => {
    dispatch({
      type: c.FETCH_COMPANY,
    });
    Api.get("company/list", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.data) {
          dispatch({
            type: c.FETCH_COMPANY_SUCCESS,
            payload: {
              data: res.data,
            },
          });
        } else {
          dispatch({
            type: c.FETCH_COMPANY_FAIL,
          });
        }
      })
      .catch((err) => {
        if (err) {
          dispatch({
            type: c.FETCH_COMPANY_FAIL,
          });
        }
      });
  };
  