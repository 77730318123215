export default function fixDecimalNumber(input) {
  let t = input.toString().split(",")[1];
  let reverse = [];
  t.split("").map((v) => {
    reverse.unshift(v);
  });
  let intReverse = Number.parseInt(reverse.join(""));
  reverse = [];
  intReverse
    .toString()
    .split("")
    .map((v) => {
      reverse.unshift(v);
    });
  if (reverse.length === 1) {
    reverse.push("0");
  }
  let output = reverse.join("");
  return `${input.split(",")[0]},${output}`;
}
