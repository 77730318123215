import { combineReducers } from "redux";
import Auth from "./authReducer/authReducer";
import userFetch from "./userFetchReducer/userFetch";
import orderReducer from "./Orders/orderReducer";
import categoryReducer from "./Categories/categoryReducer";
import mainCategoryReducer from "./MainCategories/mainCategoryReducer";
import productReducer from "./Products/productReducer";
import productUpdateReducer from "./Products/productUpdateReducer";
import clientReducer from "./ClientReducer/ClientReducer";
import allClientReducer from "./ClientReducer/allClientreducer";
import categoryIdReducer from "./CategoryId/categoryIdReducer";
import ExportReducer from "./ExportReducer/exportReducer";
import RepReducer from "./RepReducer/repReducer.js";
import companyReducer from "./Company/companyReducer";
const reducers = combineReducers({
  auth: Auth,
  user: userFetch,
  orders: orderReducer,
  mainCategories: mainCategoryReducer,
  categories: categoryReducer,
  updatedProduct: productUpdateReducer,
  products: productReducer,
  clients: clientReducer,
  allClients: allClientReducer,
  category: categoryIdReducer,
  exportedOrders: ExportReducer,
  rep: RepReducer,
  company: companyReducer,
});

export default reducers;
