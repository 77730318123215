import * as c from '../../actions/Clients/types';

const INITIAL_STATE = {
  loading: false,
  fail: false,
  data: null,
  pagination: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case c.FETCH_ALL_CLIENTS:
      return {
        ...state,
        loading: true,
      };
    case c.FETCH_ALL_CLIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        pagination: action.payload.pagination,
      };
    case c.FETCH_ALL_CLIENTS_FAIL:
      return {
        ...state,
        loading: false,
        fail: true,
      };
    default:
      return state;
  }
};
