import { FETCH_MAIN_CATEGORIES, FETCH_MAIN_CATEGORIES_SUCCESS, FETCH_MAIN_CATEGORIES_FAIL } from './types';
import Api from '../../api/api';

export const fetchMainCategories = () => (dispatch, getState) => {
    dispatch({
        type: FETCH_MAIN_CATEGORIES,
    })
    Api.get('typeproduct/id', {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    }).then(res => {
        if (res.data) {
            dispatch({
                type: FETCH_MAIN_CATEGORIES_SUCCESS,
                payload: {
                    data: res.data
                }
            })
        } else {
            dispatch({
                type: FETCH_MAIN_CATEGORIES_FAIL,
            })
        }
    }).catch(err => {
        console.log(err)
        dispatch({
            type: FETCH_MAIN_CATEGORIES_FAIL,
        })
    })
}