import { FETCH_MAIN_CATEGORIES, FETCH_MAIN_CATEGORIES_SUCCESS, FETCH_MAIN_CATEGORIES_FAIL } from '../../actions/MainCategory/types';

const INITIAL_STATE = {
    loading: false,
    fail: false,
    mainCategories: [],
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_MAIN_CATEGORIES:
            return {
                ...state,
                loading: true,
            }
        case FETCH_MAIN_CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false,
                fail: false,
                mainCategories: action.payload.data,
            }
        case FETCH_MAIN_CATEGORIES_FAIL:
            return {
                ...state,
                loading: false,
                fail: true,
            }
        default:
            return state
    }
}

