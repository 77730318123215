import {
    UPDATE_PRODUCT,
    UPDATE_PRODUCT_FAIL,
    UPDATE_PRODUCT_SUCCESS
} from '../../actions/productUpdate/types';

const INITIAL_STATE = {
    productEdited: null,
    fail: false,
    loading: false,
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_PRODUCT:
            return {
                ...state,
                loading: true,

            }
        case UPDATE_PRODUCT_FAIL:
            return {
                ...state,
                loading: false,
                fail: true,
            }
        case UPDATE_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                productEdited: action.payload.data
            }

        default:
            return state
    }
}