import React, { useState } from 'react';
import { Modal, Button, Input, Select, notification } from 'antd';
import * as styles from './index.module.scss';
// import formatPrice from '../../utils/priceFormatter';
import { fetchCategories } from '../../actions/Category/actions';
import { createProduct } from '../../actions/Product/actions';
import fixDecimalPlace from '../../utils/productPriceFormatter';
import { useSelector } from 'react-redux';

export default function AddProductModal({ visibility, dispatch }) {
  const [page, setPage] = useState(1);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [type_product_id, settProductId] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [sub_category, setSubCategory] = useState();
  const [sub_categoryName, setSubCategoryName] = useState('');
  const [limit_month, setLimitMonth] = useState('');
  const [price, setPrice] = useState('0,000');
  const [masss_unit_quantity, setMassUnitQuantity] = useState('40000');
  const [url, setUrl] = useState('');
  const [package_type_id, setPackageType] = useState('1');
  const [mass_unit, setMassUnit] = useState('sc');
  const categories = useSelector((state) => state.categories)
  const { mainCategories } = useSelector((state) => state.mainCategories)
  const { Option } = Select;
  
  const handleSelect = (value) => {
    settProductId(value);
    dispatch(
      fetchCategories({
        category: value,
      })
    );
    const SelectedmainCategories = mainCategories.filter(
      (item)=> item.id === value,
    )[0]
    setCategoryName(SelectedmainCategories.name)
  };
  const handleSubCategories = (value) => {
    setSubCategory(value);
    let filteredData = categories.categories.filter(
      (item) => item.id === value
    )[0];

    setSubCategoryName(filteredData.name);
  };
  const handleNext = () => {
    if (page === 1) {
      if (
        name === '' ||
        description === '' ||
        price === '' ||
        price === "0,000" ||
        limit_month === ''
      ) {
        notification.warn({
          message: 'Preencha todos os campos!',
        });
      } else {
        setPage(page + 1);
      }
    } else if (page === 2) {
      if (type_product_id === '' || url === '' || sub_category === '' || sub_category == null || type_product_id == null || url == null) {
        notification.warn({
          message: 'Preencha todos os campos!',
        });
      } else {
        setPage(page + 1);
      }
    }
  };

  const handlePrev = () => {
    setPage(page - 1);
  };
  const handleMassUnit = (value) => {
    setMassUnit(value);
  };
  const renderOption = () => {
    if (page === 1) {
      return (
        <div className={styles.inputsContainer}>
          <Input
            value={name}
            placeholder="Nome Do Produto"
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            value={description}
            placeholder="Descrição (Link) "
            onChange={(e) => setDescription(e.target.value)}
          />
          <Input
            placeholder="Preço do Produto"
            value={price ? fixDecimalPlace(price) : '0,00'}
            onChange={(e) => setPrice(e.target.value)}
          />
          <Select
            optionFilterProp="children"
            style={{ widht: 'auto', marginTop: 10 }}
            value={limit_month ? limit_month : 'Selecione o mês limite.'}
            onSelect={(value) => setLimitMonth(value)}
            placeholder="Selecione o mês limite."
          >
            <Option value="jun">Junho</Option>
            <Option value="jul">Julho</Option>
            <Option value="ago">Agosto</Option>
            <Option value="set">Setembro</Option>
            <Option value="out">Outubro</Option>
            <Option value="nov">Novembro</Option>
            <Option value="dez">Dezembro</Option>
          </Select>
        </div>
      );
    } else if (page === 2) {
      return (
        <div className={styles.inputsContainer}>
          <Select
            optionFilterProp="children"
            style={{ widht: 'auto', marginBottom: 10 }}
            value={categoryName ? categoryName : 'Selecione Categoria'}
            onSelect={(value) => handleSelect(value)}
            placeholder="Selecione Categoria"
          >
            {mainCategories.map((item) => (
              <Option key={item.id} value={item.id}>
              {item.name}
              </Option>
            ))}
          </Select>

          <Select
            optionFilterProp="children"
            style={{ widht: 'auto', marginBottom: 10 }}
            value={mass_unit ? mass_unit : 'Selecione Categoria'}
            onSelect={(value) => handleMassUnit(value)}
            placeholder="Selecione a Unidade"
          >
            <Option value="sc">SC 40kg</Option>
            <Option value="kg">Kg</Option>
            <Option value="ton">Ton</Option>
          </Select>
          <Select
            optionFilterProp="children"
            style={{ widht: 'auto', marginBottom: 10 }}
            value={package_type_id ? package_type_id : 'Selecione Tipo'}
            onSelect={(value) => setPackageType(value)}
            placeholder="Selecione o tipo"
          >
            <Option value="1">Ensacado</Option>
            <Option value="2">Granel</Option>
          </Select>
          <Select
            optionFilterProp="children"
            style={{ width: '200px', marginBottom: 10 }}
            onChange={(value) => handleSubCategories(value)}
            placeholder="Selecione Sub Categoria"
          >
            {type_product_id ? categories.categories.map((item, index) => (
              <Option key={index + 1} value={item.id}>
                {item.name}
              </Option>
            )) :
            [].map((item, index) => (
              <Option key={index + 1} value={item.id}>
                {item.name}
              </Option>
            ))
            }
          </Select>
          <Input
            placeholder="Url da Imagem"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
        </div>
      );
    } else {
      return (
        <div className={styles.inputsContainer}>
          <div className={styles.overview}>
            <img src={url} alt={name} style={{ maxWidth: '50%' }} />
            <span>Nome: {name}</span>
            <span>Preço: {fixDecimalPlace(price)}</span>
            <span>Mês Limite: {limit_month}</span>
            <span>Categoria: {categoryName}</span>
            <span>SubCategoria: {sub_categoryName} </span>
            <span>
              Descrição (LINK): <a href={description}>Clique Aqui</a>{' '}
            </span>
          </div>
        </div>
      );
    }
  };

  const handleSubmit = () => {
    // visibility[1](!visibility[0]);
    if (mass_unit === 'sc') {
      setMassUnitQuantity('40000');
    }
    if (mass_unit === 'kg') {
      setMassUnitQuantity('1000');
    }
    if (mass_unit === 'ton') {
      setMassUnitQuantity('1000000');
    }
    let payload = {
      name,
      description,
      type_product_id: sub_category.toString(),
      price: Number.parseInt(price.replace(',', '').replace('.', '')),
      url,
      mass_unit,
      limit_month,
      masss_unit_quantity,
      package_type_id,
      decimal_places: 3,
    };
    dispatch(createProduct(payload));
  };
  return (
    <Modal
      closable={false}
      visible={visibility[0]}
      okText="Criar Produto "
      okButtonProps={{ disabled: page > 2 ? false : true }}
      cancelText="Cancelar"
      onOk={() => handleSubmit()}
      onCancel={() => visibility[1](!visibility[0])}
    >
      <div className={styles.modalContainer}>
        <h2 style={{ color: 'white', fontWeight: 'bold' }}>Novo Produto</h2>
        {renderOption()}
        <div className={styles.nxtPrevContainer}>
          {page === 3 ? null : (
            <Button onClick={() => handleNext()}>Próximo</Button>
          )}
          {page <= 1 ? null : (
            <Button onClick={() => handlePrev()}>Voltar</Button>
          )}
        </div>
      </div>
    </Modal>
  );
}
