import {
    FETCH_USER,
    FETCH_USER_FAIL,
    FETCH_USER_SUCCESS
} from './types';

import Api from '../../api/api';

export const fetchUser = () => (dispatch, getState) => {
    dispatch({ type: FETCH_USER });
    Api.get('user', {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    }).then(res => {
        if (res.data) {
            dispatch({
                type: FETCH_USER_SUCCESS,
                payload: {
                    data: res.data
                }
            })
        } else {
            dispatch({ type: FETCH_USER_FAIL })
        }
    }).catch(err => dispatch({ type: FETCH_USER_FAIL }))
}