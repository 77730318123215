import React, { useState, useEffect } from "react";
import { Layout, Button, Modal, Input, notification, Select } from "antd";
import MenuItens from "../../components/MenuItens";
import Header from "../../components/Header";
import * as styles from "./index.module.scss";
import { connect, useSelector } from "react-redux";
import PreClientsTable from "../../components/PreClientsTable";
import {
  fetchClientsByStatus,
  createClient,
} from "../../actions/Clients/clientsActions";
import { fetchUser } from "../../actions/userFetchAction/userFetchAction";
import LoadingSpin from "../../components/LoadingSpin";
import { cpfMask } from "../../utils/cpfMask";
import phoneMask from "../../utils/phoneMask";
import { fetchCompanies } from "../../actions/Company/actions";
import maskCode from "../../utils/maskCode";

function PendingClients(props) {
  const [collapsed, setCollapsed] = useState(false);
  const [cnpj_cpf, setCnpjCpf] = useState("");
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [filterName, setFilterName] = useState("");
  const [agroSelected, setAgro] = useState(false);
  const [vendSelected, setVend] = useState(false);
  const [visible, setVisible] = useState(false);
  const [company_code, setCompanyCode] = useState("");
  const [nameCompany, setNameCompany] = useState("");
  const [successDelete, setSuccessDelete] = useState(false);
  const [successEdit, setSuccessEdit] = useState(false);
  const [successCreated, setSuccessCreated] = useState(false);
  const { Sider, Content } = Layout;
  const { Option } = Select;
  const data = useSelector((state) => state.clients.data);
  const { pagination, loading, edited, deleted, created } = useSelector((state) => state.clients);
  const user = useSelector((state) => state.user);
  const { data: dataCompany } = useSelector((state) => state.company);

  const handleCleanModal = () => {
    setCnpjCpf("")
    setCode("")
    setName("")
    setEmail("")
    setPhoneNumber("")
    setAgro(false)
    setVend(false)
    setVisible(false)
    setCompanyCode("")
    setNameCompany("")
  }

  useEffect(() => {
    props.dispatch(fetchUser());
    props.dispatch(fetchClientsByStatus(1, "old", ''));
  }, [props]);

  useEffect(() => {
    //add
    if(created.fail) notification.error({ message: created.message});  
    if(created.loading) setSuccessCreated(true)
    if(!created.loading && successCreated && !created.fail) {
      notification.success({ message: "Usuário criado com sucesso!" });
      setSuccessDelete(false)
      Modal.destroyAll();
      handleCleanModal()
    }
  }, [created.fail, created.loading, created.message, successCreated]);

  useEffect(() => {
    //edit
    if(edited.fail) notification.error({ message: "Aconteceu um erro na tentativa de editar o usuário" });
    if(edited.loading) setSuccessEdit(true)
    if(!edited.loading && successEdit && !edited.fail) {
      notification.success({ message: "Usuário editado com sucesso!" });
      setSuccessDelete(false)
    }
  }, [edited.fail, edited.loading, successEdit]);

  useEffect(() => {
    // deleted
    if(deleted.fail) notification.error({ message: "Aconteceu um erro na tentativa de deletar o usuário" });
    if(deleted.loading) setSuccessDelete(true)
    if(!deleted.loading && successDelete && !deleted.fail) {
      notification.success({message: "Usuário deletado com sucesso!",});
      setSuccessDelete(false)
    }
  }, [deleted.fail, deleted.loading, successDelete]);

  const handleSubmit = () => {
    let payload = {
      name,
      mail: email,
      phone: phone_number
        .replace("(", "")
        .replace(")", "")
        .replace("-", "")
        .replace(/\s+/g, ""),
      user_type: agroSelected ? "client" : "rep",
      code,
      cpf_cnpj: cnpj_cpf.replace(".", "").replace(".", "").replace("-", ""),
      company_code,
    };

    if (
      payload.name === "" ||
      payload.mail === "" ||
      payload.phone === "" ||
      payload.user_type === "" ||
      payload.code === "" ||
      payload.cpf_cnpj === "" ||
      (!agroSelected && !vendSelected)
    ) {
      notification.warn({
        message: "Por favor insira todos os campos",
      });
    } else if (vendSelected && payload.company_code === "") {
      notification.warn({
        message: "É necessário informar uma empresa para os vendedores",
      });
    } else {
      props.dispatch( async () => {
        await createClient(payload)(props.dispatch)
        await fetchClientsByStatus(1, "old", filterName)(props.dispatch)
      });
    }
  };

  return (
    <Layout>
      <Sider
        collapsible
        collapsedWidth="0"
        trigger={null}
        collapsed={collapsed}
        style={{
          height: "100%",
          backgroundColor: "white",
          width: "auto",
        }}
      >
        <MenuItens />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          justifyContent: "center",
          background: "#001529",
          minHeight: "100vh",
          zIndex: 999,
        }}
      >
        <Header
          name={user.user ? user.user.name : ""}
          pageName="Clientes Pré Cadastrado"
          collapsed={[collapsed, setCollapsed]}
        />
        <Content
          style={{
            justifyContent: "space-around",
            padding: 24,
          }}
        >
          <LoadingSpin loading={data == null}>
            <Modal
              title="Adicionar novo cliente."
              visible={visible}
              onCancel={() => setVisible(!visible)}
              onOk={handleSubmit}
              okText="Criar "
              cancelText="Cancelar"
              style={{
                fontFamily: "Kostic_Roc_Grotesk_Regular",
                background: "#001529",
              }}
            >
              <div className={styles.modalContainer}>
                <div className={styles.inputContainerAdd}>
                  <div className={styles.inputs}>
                    <h2 style={{ color: "white", textAlign: "center" }}>
                      Novo Cliente
                    </h2>
                    <Input
                      placeholder="Nome"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <Input
                      required
                      style={{ marginTop: 10 }}
                      placeholder="CPF / CNPJ"
                      type="text"
                      value={cpfMask(cnpj_cpf)}
                      maxLength={18}
                      onChange={(e) => setCnpjCpf(e.target.value)}
                    />
                    <Input
                      required
                      style={{ marginTop: 10 }}
                      placeholder="Email"
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Input
                      required
                      style={{ marginTop: 10 }}
                      placeholder="Telefone"
                      type="text"
                      maxLength={15}
                      value={phoneMask(phone_number)}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    <Input
                      required
                      style={{ marginTop: 10 }}
                      placeholder="Matrícula"
                      type="text"
                      value={code}
                      onChange={(e) => setCode(maskCode(e.target.value))}
                    />
                    {vendSelected ? (
                      <Select
                        optionFilterProp="children"
                        style={{ marginTop: 10, width: "260px" }}
                        value={
                          nameCompany ? nameCompany : "Selecione uma empresa"
                        }
                        onSelect={(value) => {
                          const valueObj = JSON.parse(value);
                          setNameCompany(valueObj.name);
                          setCompanyCode(valueObj.code);
                        }}
                        placeholder="Selecione uma empresa"
                      >
                        {dataCompany.map((item) => (
                          <Option key={item.code} value={JSON.stringify(item)}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    ) : null}
                  </div>
                  <div className={styles.button}>
                    <Button
                      type="primary"
                      disabled={vendSelected ? true : false}
                      style={{
                        backgroundColor: agroSelected ? "green" : "transparent",
                        border: agroSelected ? "none" : "",
                        color: "white",
                        marginRight: 5,
                      }}
                      onClick={() => setAgro(!agroSelected)}
                    >
                      Agropecuarista
                    </Button>

                    <Button
                      type="primary"
                      disabled={agroSelected ? true : false}
                      style={{
                        backgroundColor: vendSelected ? "green" : "transparent",
                        border: vendSelected ? "none" : "",
                        color: "white",
                      }}
                      onClick={() => {
                        props.dispatch(fetchCompanies());
                        setVend(!vendSelected);
                      }}
                    >
                      Vendedor
                    </Button>
                  </div>
                </div>
              </div>
            </Modal>
            <div className={styles.filterContainer}>
              <div className={styles.addButtonContainer}>
                <Button
                  type="primary"
                  size="large"
                  style={{
                    backgroundColor: "#37724a",
                    textTransform: "uppercase",
                    fontFamily: "Kostic_Roc_Grotesk_Regular",
                    border: "none",
                  }}
                  onClick={() => setVisible(!visible)}
                >
                  Adicionar Cliente
                </Button>
              </div>
              <div className={styles.inputContainer}>
                <Input
                  style={{ width: "200px" }}
                  placeholder="Filtrar por nome..."
                  onChange={(e) => setFilterName(e.target.value)}
                />
                {<Button
                  onClick={() =>
                    props.dispatch(
                      fetchClientsByStatus(1, "old", filterName)
                    )
                  }
                  type="primary"
                  size="medium"
                  style={{
                    height: "max",
                    backgroundColor: "#37724a",
                    textTransform: "uppercase",
                    fontFamily: "Kostic_Roc_Grotesk_Regular",
                    border: "none",
                    marginLeft: "10px",
                  }}
                >
                  Procurar
                </Button>}
              </div>
            </div>
            <div className={styles.clientsContainer}>
              <PreClientsTable
                name={filterName}
                data={data ? data : []}
                pagination={pagination ? pagination : ""}
                loading={loading}
                dispatch={props.dispatch}
                edited={edited}
                filterName={filterName}
              />
            </div>
          </LoadingSpin>
        </Content>
      </Layout>
    </Layout>
  );
}

export default connect()(PendingClients);
