import React from "react";
import { Table, Tag, Empty } from "antd";
import { cpfMask } from "../../utils/cpfMask";

import "./style.css";
import phoneMask from "../../utils/phoneMask";
import { fetchallClients } from "../../actions/Clients/clientsActions";

export default function ClientsTable(props) {
  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      render: (name) => (
        <span style={{ color: "white", fontWeight: "bold" }}>{name}</span>
      ),
    },
    {
      title: "CPF/CNPJ",
      dataIndex: "cnpj_cpf",
      render: (cnpj_cpf) => (
        <span style={{ color: "white", fontWeight: "bold" }}>
          {cpfMask(cnpj_cpf)}
        </span>
      ),
    },
    {
      title: "Matricula",
      dataIndex: "code",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Tipo de usuário",
      dataIndex: "person_type",
      render: (person_type) => (
        <span>
          {person_type === "rep" ? (
            <Tag color="gold">Vendedor</Tag>
          ) : (
            <Tag color="green">Agropecuarista</Tag>
          )}
        </span>
      ),
    },
    {
      title: "Telefone",
      dataIndex: "phone_number",
      render: (phone_number) => (
        <span style={{ color: "white", fontWeight: "bold" }}>
          {phoneMask(phone_number)}
        </span>
      ),
    },
  ];
  const handlePagination = (value) => {
    props.dispatch(fetchallClients(value));
  };
  return (
    <Table
      columns={columns}
      dataSource={props.data}
      rowKey="cnpj_cpf"
      className={"RCM_two_level_table1"}
      scrollToFirstRowOnChange={true}
      pagination={{
        onChange: handlePagination,
        current: props.pagination.page ? props.pagination.page : 1,
        pageSize: 10,
        total: props.pagination ? props.pagination.total : null,
        showTotal: (total, range) => (
          <span style={{ color: "white" }}>
            {range[0]} - {range[1]} de total contratos
          </span>
        ),
      }}
      locale={{
        emptyText: <Empty description="Sem clientes disponíveis no momento" />,
      }}
      style={{
        width: "100%",
        minWidth: "100%",
        overflow: "hidden",
        borderRadius: 10,
        fontFamily: "Kostic_Roc_Grotesk_Regular                ",
      }}
    />
  );
}
