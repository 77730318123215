import React, { useEffect } from "react";
import { Layout, notification } from "antd";
import MenuItens from "../../components/MenuItens";
import { useState } from "react";
import { connect, useSelector } from "react-redux";
import { fetchUser } from "../../actions/userFetchAction/userFetchAction";

import * as styles from "./index.module.scss";
import Header from "../../components/Header";
import LoadingSpin from "../../components/LoadingSpin";
import NewClientsTable from "../../components/NewClientsTable";
import { fetchClientsByStatus } from "../../actions/Clients/clientsActions";
function NewClients(props) {
  const { Sider, Content } = Layout;
  const { data, loading, pagination, deleted, accepted } = useSelector((state) => state.clients);
  const user = useSelector((state) => state.user);
  const [successDelete, setSuccessDelete] = useState(false);
  const [successAccepted, setSuccessAccepted] = useState(false);

  useEffect(() => {
    props.dispatch(fetchUser());
    props.dispatch(fetchClientsByStatus(1, "new", true));
  }, [props]);
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    //edit
    if(accepted.fail) notification.error({ message: "Aconteceu um erro na tentativa de aceitar o usuário" });
    if(accepted.loading) setSuccessAccepted(true)
    if(!accepted.loading && successAccepted && !accepted.fail) {
      notification.success({ message: "Usuário aprovado com sucesso!" });
      setSuccessDelete(false)
    }
  }, [accepted.fail, accepted.loading, successAccepted]);

  useEffect(() => {
    // deleted
    if(deleted.fail) notification.error({ message: "Aconteceu um erro na tentativa de recusar o usuário" });
    if(deleted.loading) setSuccessDelete(true)
    if(!deleted.loading && successDelete && !deleted.fail) {
      notification.success({message: "Usuário recusado com sucesso!",});
      setSuccessDelete(false)
    }
  }, [deleted.fail, deleted.loading, successDelete]);


  return (
    <Layout>
      <Sider
        collapsible
        collapsedWidth="0"
        trigger={null}
        collapsed={collapsed}
        style={{
          height: "100%",
          backgroundColor: "white",
          width: "auto",
        }}
      >
        <MenuItens />
      </Sider>
      <Layout
        className="site-layout"
        style={{
          justifyContent: "center",
          background: "#001529",
          minHeight: "100vh",
          zindex: "999",
        }}
      >
        <Header
          name={user.user ? user.user.name : ""}
          pageName="Novos Clientes"
          collapsed={[collapsed, setCollapsed]}
        />
        <Content
          style={{
            justifyContent: "space-around",
            padding: 24,
          }}
        >
          <LoadingSpin loading={data == null}>
            <div className={styles.filterContainer}></div>
            <div className={styles.newClientsContainer}>
              <NewClientsTable
                data={data ? data : []}
                pagination={pagination ? pagination : ""}
                dispatch={props.dispatch}
                loading={loading}
              />
            </div>
          </LoadingSpin>
        </Content>
      </Layout>
    </Layout>
  );
}
export default connect()(NewClients);
